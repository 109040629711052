import React, {Component} from 'react';
import './page-not-found.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class PageNotFoundPage extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <div>
                <Breadcrumb activePageName={'404'}/>
                <div className="col-xs-12 ground-color text-center m-5">
                    <div className="container-error-404">
                        <h1>404</h1>
                        <div className="msg">OH!<span className="triangle"></span></div>
                    </div>
                    <h2 className="h1">Sorry! Page not found</h2>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageNotFoundPage))