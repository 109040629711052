

import React, {Component} from 'react';
import './about.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import CommonHelper from "../../helper/CommonHelper";
import Pagination from "react-js-pagination";




class AboutPage extends Component {
   constructor(props) {
      super(props);
      this.state = {
          news: [],
          activePage: 1,
          per_page: 3,
          total_records: 3,
      };

      this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
      const getPageNum = localStorage.getItem('newsPage');
      this.getNews(getPageNum);


      new window.Swiper('.flex-partners', {
         slidesPerView: 7,
         loop:true,
         autoplay: {
             delay: 1000,
         },
         breakpoints: {
            0: {
                slidesPerView: 3,
            },
            478: {
                slidesPerView: 3,
            },
            576: {
                slidesPerView: 4,
            },
            768: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 6,
            },
            1200: {
                slidesPerView: 6,
            },
        },
      });

  }

  getNews(pageNumber) {
      let page = (pageNumber) ? pageNumber : 1;
      CommonHelper.get('getNews?page=' + page, (status, res) => {
          this.setState({
              news: res.data.data,
              /*per_page: res.data.per_page,
              total_records: res.data.total,*/
              per_page: 3,
              total_records: 3,
              activePage: parseInt(pageNumber)
          });
      })
  }

  handlePageChange(pageNumber) {
      localStorage.setItem('newsPage', pageNumber);
      this.getNews(pageNumber)
  }





render() {



return (
<div className={'about-us-page  abouthding'}>
<div className="wrapper-content">
          <div className="video-slider">
          <div className="container">
                <video width="100%" height="100%" id="myVideo" muted autoPlay={true} playsInline={true}>
                    <source src="images/Logos.mp4" type="video/mp4" />
                  </video>
          </div>
        </div> 
         <div className="container">
            <div className="tectical-about mt-5">
               <div className="pera-content mt-2">
                  <p className="text-center">Aspetto provides high-quality, exceptional products and professional services for those who serve and protect our nation.
                  For over a decade, Aspetto’s tactical product and information technology teams have been producing cutting edge innovative products and professional services for DoD, federal agencies, state and local law enforcement, commercial corporations, and strategic government contracting partnerships. Since our introduction of "America's First Bullet-Resistant Clothing Line," our product and service offerings keep expanding to provision top-of-the-line national security products and services; ensuring the safety, security, and success of the individuals and operational systems that keep our nation safe.
                  </p>

                  <p className="text-center mt-2">
                  From Tech to Tactical – We’ve Got You Covered
                  </p>

                  <div className='heading-certifi'>
                  
                     <h3 className="heading-left">We are ISO 9001:2015 Certified</h3>
                     <span>CAGE Code:  6EMD9  / DUNS:  832783521 / UEI: Y7QYC9NNFMW8</span>
                  </div>
                  <div className="bg-content-tectical text-center">
                  <div className="btn-group">
                      <Link to={'/getquote'} className="btn btn-tectical">
                      Request a Quote
                      </Link>
                  </div>
            </div>

                  
               </div>

            </div>
         </div>
      </div>
      <div className="tectical-partneers">
      <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-sm-12 col-md-12">
                <h3>Contract Vehicles</h3>
                <p>Make it simple, use our contract vehicles. We’re SBA 8(a) and HUBZone Certified.</p>
                {/*<img src="images/army-man.png">*/}
              </div>

              <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
                <div className="flex-partners mt-0 about-vehicles">
                  <div className="item"><img src="images/clintes-01.png" /></div>
                  <a className="item" href="https://www.gsa.gov/technology/technology-purchasing-programs/governmentwide-acquisition-contracts/8a-stars-iii" target={'blank'}><img src="images/clintes-02.png" />
                    <span className='pending-text'>47QTCB22D0336</span>
                    <span className='pending-text pedding-text-2'>stars3@aspettoinc.com</span>
                  </a>
                  <div className="item"><img src="images/clintes-03.png" /></div>
                  <div className="item"><img src="images/clintes-07.png" /></div>
                  <div className="item"><img src="images/clintes-06.png" /></div>
                  <div className="item"><img src="images/clintes-08.png" /></div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="leadership-team">
         <div className="container">
            <h3  className="text-end">Leadership</h3>
            <div className="row-table">
            
                  <div className="item-teams">
                     <div className="team-thumb">
                        <img src="./images/team-01.jpg" alt="ceo"/>
                        <h2>CEO <span>ABBAS HAIDER</span></h2>
                        <a href="https://www.linkedin.com/in/abbas-haider-45969110" target={'blank'}><i className="fab fa-linkedin"></i></a>
                     </div>
                     <div className="leader-content">
                        <p>Abbas Haider, included on the impressive Forbes 30 Under 30 Class of 2018 list, has been an entrepreneur for many years. From founding an import and export company to establishing a multi-million dollar brand, Abbas' business experience is wide and varied. He is a well-respected speaker and has given speeches at many business-related conferences, including TEDx, Forbes Summit, and OPEN DC. In addition, he has served on the executive advisory board at the University of Mary Washington and was awarded the 2018 Young Business Alumni Award at the university. Abbas was also included in the Maverick Pac’s Future 40 Class of 2019.</p>
                        <p>Abbas' company, Aspetto, was founded in 2008 and focuses on producing protective service equipment offerings such as cybersecurity/IT services, logistics, ballistics equipment, and other items produced to help the government and private companies achieve their multi-faceted defense-related goals. Abbas’s work has been featured via a wide array of media platforms, including Forbes, Esquire, Newsweek, CNBC, and other prominent media channels.</p>
                     </div>
                  </div>
              
             
                  <div className="item-teams">
                     <div className="team-thumb">
                        <img src="./images/team-02.jpg" alt="ceo"/>
                        <h2>COO <span>Robert Davis</span></h2>
                        <a href="https://www.linkedin.com/in/rd-aspetto" target={'blank'}><i className="fab fa-linkedin"></i></a>
                     </div>
                     <div className="leader-content">
                        <p>Robert Davis is the co-founder and COO of Aspetto and joins business partner Abbas on the Forbes Magazine 30 Under 30 list for Manufacturing &amp; Industry. Robert is in charge of overseeing all manufacturing operations as well as handling research and development duties.
                           An alumnus of the University of Mary Washington (UMW) School of Business in Virginia, Robert was selected to the UMW College of Business Alumni Panel and also the executive advisory board. A recipient of the Young Business Alumni Award, Robert has been recognized for his many business contributions to the Aspetto brand name.
                        </p>
                        <p>Robert is also well-known on the professional speaker circuit. Some of the business conferences where Robert has been a featured speaker include TEDx, Forbes Summit, and other large audience business conferences. Taking his in-depth knowledge of the industry and using it to encourage and educate the masses is what Robert strives to do and ultimately accomplishes.
                        </p>
               
                     </div>
                  </div>
              
              
             {/*      <div className="item-teams">
                     <div className="team-thumb">
                        <img src="./images/team-03.jpg" alt="ceo"/>
                        <h2>CSO<span>Greg Dyer</span></h2>
                        <a href="https://www.linkedin.com/in/gregorydyerjr" target={'blank'}><i className="fab fa-linkedin"></i></a>
                     </div>
                     <div className="leader-content">
                     <p>Greg Dyer joined Aspetto as Chief of Strategy & Technical Growth Officer in 2020. He has over 20 years of large and small business executive leadership with a proven track record of corporate growth strategy, operational excellence, and mergers and acquisition.</p>

                     <p>Prior to joining Aspetto, Greg was a Managing Partner and Chief Strategy Officer of Spinvi Consulting, LLC., where he helped develop and lead the company’s growth strategy, growing the organization from ~$3M to > $115M in annual revenue over a 3-year period which ultimately led to a successful acquisition. During his tenure, Spinvi was awarded “America's Best Startup Employers” for American businesses meeting Forbes Magazine’s employer reputation, employee satisfaction and growth criteria and ranked 1st place for Seaport-e and Seaport NxG total awarded small business obligations for both 2019 and 2020, as well as ranked 8th overall in FY20 for all Seaport-e contractors (both large and small businesses combined).</p>
                       
                     </div>
                  </div>*/}
              
            </div>
            <div className="row ">

               <div className="col-md-6">
                  {/*<div className="item-teams">
                     <div className="team-thumb">
                        <img src="./images/team-04.jpg" alt="ceo"/>
                        <h2>Board of Advisors <span>Dr. Pete Johnson </span></h2>
                     </div>
                     <div className="leader-content">
                        <p>Robert Davis is the co-founder and COO of Aspetto and joins business partner Abbas on theForbes Magazine 30 Under 30 list for Manufacturing &amp; Industry. Robertis in charge of overseeing all manufacturing operations as well as handling research and development duties.
                           An alumnus of the University of Mary Washington (UMW) School of Business in Virginia, Robert was selected to the UMW College of Business Alumni Panel and also the executive advisory board. A recipient of the Young Business Alumni Award, Robert has been recognized for his many business contributions to the Aspetto brand name.
                        </p>
                        <p>Robert is also well-known on the professional speaker circuit. Some of the business conferences where Robert has been a featured speaker include TEDx, Forbes Summit, and other large audience business conferences. Taking his in-depth knowledge of the industry and using it to encourage and educate the masses is what Robert strives to do and ultimately accomplishes.</p>
                     </div>
                  </div>*/}
               </div>
            </div>
         </div>
      </div>
	  
	    <div className="leadership-team">
         <div className="container">
            <h3  className="">Board of Advisors</h3>
            <div className="row-table">
       
                  <div className="item-teams">
                     <div className="team-thumb">
                        <img src="./images/Steve_Cakebread.jpg" alt="ceo"/>
                        <h2> <span>Steve Cakebread</span></h2>
                     </div>
                     <div className="leader-content">
                        <p>Mr. Cakebread led the financial teams that took Yext, Pandora, and Salesforce to successful initial public offerings. He is also the author of “The IPO Playbook.”  </p>
                        <br></br>
                        <a href="https://www.linkedin.com/in/todd-harbour-1224ba115/ " target={'blank'}><i className="fab fa-linkedin"></i></a>
      
                     </div>
                  </div>
              
   
                  <div className="item-teams">
                     <div className="team-thumb">
                        <img src="./images/Todd_Harbour.jpg" alt="ceo"/>
                        <h2> <span>   Todd Harbour</span></h2>
                     </div>
                     <div className="leader-content">
                        <p>Mr. Harbour has over 30 years of government and business experience. He is a former Chief Data Officer for the Central Intelligence Agency. </p>
                        <br></br>
                        <a href="https://www.linkedin.com/in/todd-harbour-1224ba115/" target={'blank'}><i className="fab fa-linkedin"></i></a>
			
                     </div>
                  </div>
          
       
                  <div className="item-teams">
                     <div className="team-thumb">
                        <img src="./images/Congressman_Tom_Davis.jpg" alt="ceo"/>
                        <h2> 
                           <span> Congressman Tom Davis</span>
                        </h2>
                     </div>
                     <div className="leader-content">
                        <p>Congressman Davis served 14 years in the U.S. House of Representatives. He served as chairman of the Committee on Oversight and Government Reform from 2003-2007. </p>
                        <a href="https://www.linkedin.com/in/tom-davis-84033225/" target={'blank'}><i className="fab fa-linkedin"></i></a>
                     </div>
                  </div>
         
			    
                 <div className="item-teams">
                     <div className="team-thumb">
                        <img src="./images/Major_General.jpg" alt="ceo"/>
                        <h2> <span> Major General Ralph Groover (Ret.)</span></h2>
                     </div>
                     <div className="leader-content">
                       
                        <p>Major General Groover, commissioned in the US Army in 1983 and served for nearly 40 years. His experience ranges from commanding multiple Special Operations units, to serving as a Senior Defense Attaché abroad.  </p>
                        <a href="https://www.nationalguard.mil/portals/31/Features/ngbgomo/bio/2/2292.html" target={'blank'}><i className="fas fa-globe"></i></a>
			
                     </div>
                  </div>
         
            </div>
         </div>
      </div>
	  
      <section className="our-clients">
         <div className="container">
            <div className="content">
               <h2 className="heading">Our Clients</h2>
            </div>
            <div className="swiper flex-partners about-partners">
               <div className="swiper-wrapper">
                  <div className="swiper-slide item"><img src="./images/our-client/01.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/1.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/02.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/03.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/04.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/05.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/06.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/07.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/08.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/09.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/10.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/11.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/12.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/13.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/14.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/15.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/16.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/17.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/18.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/19.png"/></div>
                  <div className="swiper-slide item"><img src="./images/our-client/20.png"/></div>
               </div>
           
            </div>
         </div>
      </section>
      
      <section className="our-blogs defance-blogs">
         <div className="container">
            <h1 className="heading-apparel">Press Releases </h1>
            <div className="row">
                  {this.state.news.slice(0, 3).map((news) => {
                     return <div key={news.id} className="col-lg-4 col-md-4">
                        <div className="item-blog">
                              <div className="thumb-blogs">
                                 <Link to={'/news-details/' + news.id}>
                                 <img className="" src={news.thumb}/>
                                 </Link>
                              </div>


                              <div className="content-blogs">
                                 <span className="blog-date-time">{news.createdPost}</span>
                                 <h6 className="card-title"><Link
                                    to={'/news-details/' + news.id}>{news.title}</Link></h6>
         
                                 <p className=""
                                    dangerouslySetInnerHTML={{__html: news.shortContent}}/>
                              </div>
                              {/* <div className="card-footer">
                                 <hr/>
                                 <ul className="author-2">
                                    <li><a href="#"><img className src="./images/12s.jpg" alt="..."/> &nbsp;
                                          <span> Sean</span></a></li>
                                 </ul>
                                 /.bottom_data
                                 <ul className="bottom_data pull-right ">
                                    <li><i className="fa fa-comment-o"/>200</li>
                                    <li><i className="fa fa-heart-o"/>30</li>
                                    <li><i className="fa fa-align-left"/>7 Min Read</li>
                                 </ul>
                                 /.bottom_data
                              </div>*/}
                        </div>

                     </div>
                  })}
                <div className="bg-content-tectical text-center mt-4">
                   <div className="btn-group">
                        <Link to={'/news-feed'} className="btn btn-tectical">Read More<svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M478,256,302,432l-21.21-21.2L420.6,271H34V241H420.6L280.75,101.16,302,80Z" /></svg></Link>
            
                    </div>
                </div>

            </div>
         </div>
      </section>


      <section className="cuntry-supply">
         <h1 className="heading-apparel mb-4">Our Products and Services Span The Globe</h1>
         <img src="./images/Country_chart.png"/>
      </section>


</div>
);
}
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AboutPage))
