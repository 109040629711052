import CryptoJS from "crypto-js";

export default class Config {
    static conf = {
        url: (process.env.NODE_ENV === 'development') ?
            "https://aspetto.com/public/api/" : "https://aspetto.com/public/api/",
        // url: "http://192.168.2.5/aspetto-laravel/public/api/",
        currencySymbol: '$',
        currencyName: 'USD',
        encryptKey: "pu1iQSf1eLRe5q6oJGAPd0G+j5hyrHp4KjyFX0hy0YM=",
        user: {},
        token: '',
        site_url: (process.env.NODE_ENV === 'development') ?
            "https://aspetto.com/" : "https://aspetto.com/",
        app_name: 'Aspetto Defence'
    };


    getConf(key) {
        let val = Config.conf[key];
        if (val !== undefined)
            return Config.conf[key];
        else
            return "";
    }

    setConf(k, v) {
        if (k !== "")
            Config.conf[k] = v;
    }

    static encrypt(value) {
        return CryptoJS.AES.encrypt(value, Config.conf.encryptKey).toString();
    }

    static decrypt(value, iv = false) {
        if (iv) {
            let encrypted_json = JSON.parse(atob(value));
            return Config.unserialize(CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(Config.conf.encryptKey), {
                iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
            }).toString(CryptoJS.enc.Utf8));
        }
        return CryptoJS.AES.decrypt(value, Config.conf.encryptKey).toString(CryptoJS.enc.Utf8);
    }

    static unserialize(data) {
        var that = this,
            utf8Overhead = function (chr) {
                // http://phpjs.org/functions/unserialize:571#comment_95906
                var code = chr.charCodeAt(0);
                if (code < 0x0080) {
                    return 0;
                }
                if (code < 0x0800) {
                    return 1;
                }
                return 2;
            },
            error = function (type, msg, filename, line) {
                throw new that.window[type](msg, filename, line);
            },
            read_until = function (data, offset, stopchr) {
                var i = 2, buf = [], chr = data.slice(offset, offset + 1);

                while (chr != stopchr) {
                    if ((i + offset) > data.length) {
                        error('Error', 'Invalid');
                    }
                    buf.push(chr);
                    chr = data.slice(offset + (i - 1), offset + i);
                    i += 1;
                }
                return [buf.length, buf.join('')];
            },
            read_chrs = function (data, offset, length) {
                var i, chr, buf;

                buf = [];
                for (i = 0; i < length; i++) {
                    chr = data.slice(offset + (i - 1), offset + i);
                    buf.push(chr);
                    length -= utf8Overhead(chr);
                }
                return [buf.length, buf.join('')];
            },
            _unserialize = function (data, offset) {
                var dtype, dataoffset, keyandchrs, keys, contig,
                    length, array, readdata, readData, ccount,
                    stringlength, i, key, kprops, kchrs, vprops,
                    vchrs, value, chrs = 0,
                    typeconvert = function (x) {
                        return x;
                    };

                if (!offset) {
                    offset = 0;
                }
                dtype = (data.slice(offset, offset + 1)).toLowerCase();

                dataoffset = offset + 2;

                switch (dtype) {
                    case 'i':
                        typeconvert = function (x) {
                            return parseInt(x, 10);
                        };
                        readData = read_until(data, dataoffset, ';');
                        chrs = readData[0];
                        readdata = readData[1];
                        dataoffset += chrs + 1;
                        break;
                    case 'b':
                        typeconvert = function (x) {
                            return parseInt(x, 10) !== 0;
                        };
                        readData = read_until(data, dataoffset, ';');
                        chrs = readData[0];
                        readdata = readData[1];
                        dataoffset += chrs + 1;
                        break;
                    case 'd':
                        typeconvert = function (x) {
                            return parseFloat(x);
                        };
                        readData = read_until(data, dataoffset, ';');
                        chrs = readData[0];
                        readdata = readData[1];
                        dataoffset += chrs + 1;
                        break;
                    case 'n':
                        readdata = null;
                        break;
                    case 's':
                        ccount = read_until(data, dataoffset, ':');
                        chrs = ccount[0];
                        stringlength = ccount[1];
                        dataoffset += chrs + 2;

                        readData = read_chrs(data, dataoffset + 1, parseInt(stringlength, 10));
                        chrs = readData[0];
                        readdata = readData[1];
                        dataoffset += chrs + 2;
                        if (chrs != parseInt(stringlength, 10) && chrs != readdata.length) {
                            error('SyntaxError', 'String length mismatch');
                        }
                        break;
                    case 'a':
                        readdata = {};

                        keyandchrs = read_until(data, dataoffset, ':');
                        chrs = keyandchrs[0];
                        keys = keyandchrs[1];
                        dataoffset += chrs + 2;

                        length = parseInt(keys, 10);
                        contig = true;

                        for (i = 0; i < length; i++) {
                            kprops = _unserialize(data, dataoffset);
                            kchrs = kprops[1];
                            key = kprops[2];
                            dataoffset += kchrs;

                            vprops = _unserialize(data, dataoffset);
                            vchrs = vprops[1];
                            value = vprops[2];
                            dataoffset += vchrs;

                            if (key !== i)
                                contig = false;

                            readdata[key] = value;
                        }

                        if (contig) {
                            array = new Array(length);
                            for (i = 0; i < length; i++)
                                array[i] = readdata[i];
                            readdata = array;
                        }

                        dataoffset += 1;
                        break;
                    default:
                        error('SyntaxError', 'Unknown / Unhandled data type(s): ' + dtype);
                        break;
                }
                return [dtype, dataoffset - offset, typeconvert(readdata)];
            }
        ;

        return _unserialize((data + ''), 0)[2];
    }

}