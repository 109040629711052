import React, {Component} from 'react';
import './careers.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import CommonHelper from "../../helper/CommonHelper";
import SimpleReactValidator from "simple-react-validator";

class CareersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            applyJob: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                city: '',
                job_title: '',
                current_company: '',
                exp: '',
                current_ctc: '',
                job_id: 0,
                cv: null,
                message: ''
            }
        };
        this.appliedJob = this.appliedJob.bind(this);
        this.setJobId = this.setJobId.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handleChanges = this.handleChanges.bind(this);
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.getJobsAndCategory();
    }

    getJobsAndCategory() {
        CommonHelper.post('getDefenceJob', {}, (status, res) => {
            this.setState({jobs: res.data.jobs_category})
        })
    }

    appliedJob(e) {
        e.preventDefault();
        CommonHelper.upload('applyJob', this.state.applyJob, (status, response) => {
            if (response.status) {
                this.setState({
                    applyJob: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        city: '',
                        job_title: '',
                        current_company: '',
                        exp: '',
                        current_ctc: '',
                        job_id: 0,
                        cv: null,
                        message: ''
                    }

                });
                window.$('#carrer_pop').modal('hide');
            }
        });
    }

    setJobId(id) {
        this.setState({applyJob: {job_id: id}});
    }

    handleChanges(event) {
        this.setState({applyJob: {...this.state.applyJob, [event.target.name]: event.target.value}})
    }

    onChangeHandler(event) {
        this.setState({
            applyJob: {
                ...this.state.applyJob,
                cv: event.target.files[0]
            }
        })
    }

    render() {
        return (
            <div className="abouthding">


<div className="contact-us-page">
            <div className="tectical-partneers">
                <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                    <h3> Careers</h3>
                    <p >At Aspetto, we staff our company with only the
                        best professionals in the industry. We welcome interested candidates from all
                        industries, ranging from IT professionals involved in the specialized defense
                        industry to administrative assistants who have worked directly alongside CEOs of top
                        companies nationwide.</p>
                    <p>
                        If you're looking to join a company where team collaboration connects with a
                        take-the-initiative type of leadership, we invite you to submit your resume and
                        interview to join our elite team of professionals in a variety of job areas,
                        including IT, human resources, administrative, and more.
                    </p>

                    {/*<img src="images/army-man.png">*/}
                    </div>
                </div>
                </div>
            </div>


            <section>
                        <div className="container">
                            <div className="p-4 mb-2">
                                <div className="heading-bold-content ">
                                    <h3 className="text-center">Benefits of Working at Aspetto</h3>
                                </div>
                            </div>
                            <div className="row pt-0 ">
                                <div className="col-md-4" >
                                    <div className="tech-item">
                                        <div className="content-technical">
                                            <img src="./images/medical_12935851.png" alt="partners" />
                                            <h4>Health and Wellness</h4>
                                            <ul className="benifit_list">
                                                <li>Comprehensive medical, dental, and vision plans tailored to your needs.</li>
                                                <li>Access to a health savings account (HSA) or flexible spending account (FSA) to maximize your benefits.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4" >
                                    <div className="tech-item">
                                        <div className="content-technical">
                                            <img src="./images/work-life-balance_12779668.png" alt="partners" />
                                            <h4>Work-Life Balance</h4>
                                            <ul className="benifit_list">
                                                <li>Generous paid time off and paid holidays.</li>
                                                <li>Flexible work arrangements to accommodate personal and professional needs.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4" >
                                    <div className="tech-item">
                                        <div className="content-technical">
                                            <img src="./images/hand_2855529.png" alt="partners" />
                                            <h4>Retirement Planning</h4>
                                            <ul className="benifit_list">
                                                <li>401(k) plan with company matching to help you save for the future.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4" >
                                    <div className="tech-item">
                                        <div className="content-technical">
                                            <img src="./images/professional-development_17659974.png" alt="partners" />
                                            <h4>Professional Development</h4>
                                            <ul className="benifit_list">
                                                <li>Educational assistance and training programs to support career growth.</li>
                                                <li>Opportunities to participate in specialized training and development initiatives.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4" >
                                    <div className="tech-item">
                                        <div className="content-technical">
                                            <img src="./images/register_4933520.png" alt="partners" />
                                            <h4>Employee Assistance Program</h4>
                                            <ul className="benifit_list">
                                                <li>Access to counseling and support services for mental health and wellness.</li>
                                                <li>Legal, financial, and elder care resources for a balanced lifestyle.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4" >
                                    <div className="tech-item">
                                        <div className="content-technical">
                                            <img src="./images/satisfaction_10184486.png" alt="partners" />
                                            <h4>Exclusive Employee Perks</h4>
                                            <ul className="benifit_list">
                                                <li>Discounts on various products and services through our employee perks program.</li>
                                                <li>Access to thousands of virtual courses and wellness programs.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                <section >
                    <div className="container">

                        <div className="row justify-content-center">
                                <div className="col-md-12">
                           
                                <div className="left_icon_bar">
                                    <ul id="cat_container">
                                        {this.state.jobs.map((job, i) => {
                                            return <li key={job.id}
                                                       className={(i == 0) ? 'bg_2 wow fadeInLeft active' : 'bg_2 wow fadeInLeft'}
                                                       data-wow-duration="1.3s ">
                                                <h4 className="hvr-wobble-horizontal">
                                                    <a href={'#job' + job.id} data-toggle="tab">
                                                        {job.name}
                                                    </a>
                                                </h4>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                                <div className="post_resume_section">
                                    <div className="resume_heading">
                                        <h3>Submit your resume</h3>
                                    </div>
                                    <div className="contact-form-box">
                                        <form onSubmit={this.appliedJob} id="sideform" encType={'multipart/form-data'}>
                                        <div className="row">
                                                <label className="mt-0" >Tell us your name*</label>
                                                <div className='col-md-6 col-6'>
                                                     <input type="text" placeholder="First Name" className="form-control "
                                                           name="first_name"
                                                           value={this.state.applyJob.first_name || ''}
                                                           onChange={this.handleChanges}/>
                                                    {this.validator.message('first_name', this.state.applyJob.first_name, 'required')}
                                                </div>
                                            
                                              
                                                <div className='col-md-6 col-6'>
                                                    <input type="text" placeholder="Last Name" className="form-control "
                                                           name="last_name"
                                                           value={this.state.applyJob.last_name || ''}
                                                           onChange={this.handleChanges}
                                                    />
                                                    {this.validator.message('last_name', this.state.applyJob.last_name, 'required')}
                                                </div>
                                                <label className="" >Phone</label>
                                                <div className='fotm-group'>
                                                    <input type="tel" placeholder="Phone No." className="form-control"
                                                           name="phone"
                                                           value={this.state.applyJob.phone || ''}
                                                           onChange={this.handleChanges}
                                                    />
                                                    {this.validator.message('phone', this.state.applyJob.phone, 'required')}
                                                </div>
                                                <label className="" >Email</label>
                                                <div className='fotm-group'>
                                                    <input type="email" placeholder="Email" className="form-control"
                                                           name="email"
                                                           value={this.state.applyJob.email || ''}
                                                           onChange={this.handleChanges}
                                                    />
                                                    {this.validator.message('title', this.state.applyJob.email, 'required')}
                                                </div>
                                                <label className="" >State</label>
                                                <div className='fotm-group'>
                                                    <input type="text" placeholder="State" className="form-control" name="city"
                                                           value={this.state.applyJob.city || ''}
                                                           onChange={this.handleChanges}
                                                    />
                                                    {this.validator.message('city', this.state.applyJob.city, 'required')}
                                                </div>
                                                <label className="" >How did you hear about us?</label>
                                                <div className='fotm-group'>
                                                    <textarea className="form-control" placeholder="Message" name="message"
                                                              value={this.state.applyJob.message || ''}
                                                              onChange={this.handleChanges}
                                                    />
                                                    {this.validator.message('message', this.state.applyJob.message, 'required')}
                                                </div>
                                                {/*<label className="" >Your Experience</label>
                                                <div className='fotm-group'>
                                                    <input type="text" placeholder="Experience" className="form-control"
                                                           name="exp"
                                                           value={this.state.applyJob.exp || ''}
                                                           onChange={this.handleChanges}
                                                    />
                                                    {this.validator.message('exp', this.state.applyJob.exp, 'required')}
                                                    </div>*/}

                                                    <label className="" >Choose File</label>
                                                <div className='fotm-group'>
                                                    <input type="file" placeholder="Fill name" className="form-control"
                                                           id="resumefile" name="file"
                                                           onChange={this.onChangeHandler}
                                                    />
                                                    {this.validator.message('file', this.state.applyJob.cv, 'required')}
                                                </div>
                                            <div className="col-md-12 text-center mt-3">
                                                    <span id="sideformmessage"/>
                                                    <button type={'submit'} className="btn">
                                                    Submit
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                       
                                    </div>
                                </div>
                          
                                </div>
                        </div>
                    </div>
                </section>
            </div>

                <section className="">
                    <div className="container ">
                        <div className="current_opn_dv row">
                         
                            <div className="col-lg-8 current_opening_dv_right wow fadeInRight"
                                 data-wow-duration="2.3s ">
                                <div className="tab-content">
                                    {this.state.jobs.map((job, i) => {
                                        return <div key={job.id}
                                                    className={(i == 0) ? 'tab-pane fade active in' : 'tab-pane'}
                                                    id={'job' + job.id}>
                                            <h5 className="car_hd">{job.name}</h5>
                                            <div className="accordion">
                                                {job['job_data'].map((jobs, i) => {
                                                    return <div key={jobs.id} className="carreir_collpas_pnl_row">
                                                        <div className="heading_collaps">
                                                            <div className="left_heading hvr-wobble-horizontal">
                                                                <i className="fa fa-mobile"/>
                                                                <h4>{jobs.job_title}</h4>
                                                            </div>
                                                            <div className="right_heading hvr-wobble-vertical">
                                                                <a className="accordion-toggle" data-toggle="collapse"
                                                                   data-parent="#accordion4"
                                                                   href={'#collapes' + jobs.id}>
                                                                    <i className="fa fa-angle-down"/>
                                                                    <p>Relevant Exp: {jobs.job_exp} Year</p>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div id={'collapes' + jobs.id}
                                                             className="collaps_paragrph_dv accordion-body collapse in">
                                                            <div className="paragraph_holder">
                                                                <p/>
                                                                <p><strong>Skills Required:</strong><br/>
                                                                    &nbsp;
                                                                </p>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: jobs.job_description}}>

                                                                </div>
                                                                <div className="apply_btn_dv">
                                                                    <a href="#" className="hvr-wobble-vertical"
                                                                       data-toggle="modal"
                                                                       data-target="#carrer_pop"
                                                                       data-backdrop="static"
                                                                       onClick={() => this.setJobId(job.id)}> Apply
                                                                        Now</a>

                                                                    <a href="javascript:void(0);" onClick={() => {
                                                                        window.$('.social-networks').toggleClass('open-menu');
                                                                    }} className="social-toggle"> Share this
                                                                        position </a>


                                                                    <div className="social-networks">
                                                                        <ul>

                                                                            <li className="social-facebook">
                                                                                <a target="blank" className="backtransp"
                                                                                   href="https://www.facebook.com/sharer.php?u=Aspeeto&t=Opportunities with Aspetto"><i
                                                                                    className="fa fa-facebook-square"
                                                                                    aria-hidden="true"/></a>
                                                                            </li>
                                                                            <li className="social-gplus">
                                                                                <a target="blank" className="backtransp"
                                                                                   href="https://plus.google.com/share?url=http://aspetto.com"><i
                                                                                    className="fa fa-envelope"
                                                                                    aria-hidden="true"/></a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="modal fade carrere_model" id="carrer_pop" tabIndex={-1} role="dialog"
                     aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <a href="#" data-dismiss="modal" className="pop_close_btn">
                            x</a>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="pop_container container">
                                    <div className="row">
                                        <div className="col-sm-5 text-center">
                                            <div className="logo_pop"><img src="./images/footerlogo.png" alt="Logo"/>
                                            </div>
                                            <div className="carere_heding_dv">

                                                <h5> Working at aspetto is not a job, It´s a career game changer.</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-7 carrer_frm_container popupbg">
                                            <form name="carrer_form" id="career_form"
                                                  onSubmit={this.appliedJob} encType={'multipart/form-data'}>
                                                {/*1 */}
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <select name="title" className="txt" required>
                                                            <option disabled>Tittle</option>
                                                            <option value="Mr.">Mr.</option>
                                                            <option value="Ms.">Ms.</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input type="text" className="txt"
                                                               placeholder="First Name" name="first_name"
                                                               value={this.state.applyJob.first_name || ''}
                                                               onChange={this.handleChanges}/>
                                                        {this.validator.message('first_name', this.state.applyJob.first_name, 'required')}
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <input type="text" className="txt"
                                                               placeholder="Last Name" name="last_name"
                                                               value={this.state.applyJob.last_name || ''}
                                                               onChange={this.handleChanges}
                                                        />
                                                        {this.validator.message('last_name', this.state.applyJob.last_name, 'required')}
                                                    </div>
                                                </div>
                                                {/*2 */}
                                                <div className="row">
                                                    <div className="col-sm-12 icon_row"><i className="fa fa-envelope"/>
                                                        <input type="email" className="txt"
                                                               placeholder="Email" name="email"
                                                               value={this.state.applyJob.email || ''}
                                                               onChange={this.handleChanges}
                                                        />
                                                        {this.validator.message('title', this.state.applyJob.email, 'required')}
                                                    </div>
                                                </div>
                                                {/*3 */}
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <select name="isdcode" className="txt" required>
                                                            <option value={+1}>+1</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6 icon_row mo"><i className="fa fa-mobile"/>
                                                        <input type="text"
                                                               title="10 numeric characters only"
                                                               className="txt"
                                                               placeholder="Phone Number"
                                                               name="phone"
                                                               value={this.state.applyJob.phone || ''}
                                                               onChange={this.handleChanges}
                                                        />
                                                        {this.validator.message('phone', this.state.applyJob.phone, 'required')}
                                                    </div>
                                                    <div className="col-sm-4 icon_row"><i className="fa fa-hospital-o"/>
                                                        <input type="text" className="txt"
                                                               placeholder="State " name="city"
                                                               value={this.state.applyJob.city || ''}
                                                               onChange={this.handleChanges}
                                                        />
                                                        {this.validator.message('city', this.state.applyJob.city, 'required')}
                                                    </div>
                                                </div>
                                                {/*4 */}
                                                <div className="row">
                                                    <div className="col-sm-12 icon_row"><i className="fa  fa-book"/>
                                                        <input
                                                            type="text"
                                                            className="txt"
                                                            placeholder="Job Title"
                                                            name="job_title"
                                                            value={this.state.applyJob.job_title || ''}
                                                            onChange={this.handleChanges}
                                                        />
                                                        {this.validator.message('exp', this.state.applyJob.job_title, 'required')}
                                                    </div>

                                                </div>
                                                {/*5*/}
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p className="experience"> Your Experience (years)</p>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="text"
                                                            className="txt"
                                                            placeholder="Relevent"
                                                            name="exp"
                                                            value={this.state.applyJob.exp || ''}
                                                            onChange={this.handleChanges}
                                                        />
                                                        {this.validator.message('exp', this.state.applyJob.exp, 'required')}
                                                    </div>
                                                    {/*
                <div class="col-sm-4">
                  <input type="text" name="exp_total" onKeyPress="return isNumberKey(event)" onpaste="return false;" ondrop="return false;" class="txt" placeholder="Total" required>
                </div>
*/}
                                                </div>
                                                {/*6*/}

                                                {/*7*/}
                                                <div className="row">
                                                    <div className="col-sm-12 icon_row">
                                                        <label>Upload Your CV</label>
                                                        <input
                                                            type="file"
                                                            id="cvfile"
                                                            className="txt file"
                                                            placeholder="Upload your resume "
                                                            name="file"
                                                            onChange={this.onChangeHandler}/>
                                                        <button type="submit" className="btn mt-3 text-right">APPLY
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CareersPage))