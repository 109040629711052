let addToCartProduct = {};
export const addToCart = (data, type, cb) => {
    return (dispatch, props) => {

        let price = (data.special_price) ? data.special_price : data.price;
        let image = (data.product_images) ? data.product_images[0].thumb : data.product_images_single.thumb;
        let size = '';
        if (data.selectedSize)
            size = data.selectedSize;

        if (addToCartProduct[data.id]) {
            let qty;
            if (type == 'remove') {
                if ((addToCartProduct[data.id].qty - 1) > 0)
                    qty = parseInt(addToCartProduct[data.id].qty - 1);
                else
                    delete addToCartProduct[data.id];
            }
            else if (type == 'addBulk')
                qty = data.qty;
            else
                qty = parseInt(addToCartProduct[data.id].qty + 1);


            if (addToCartProduct[data.id])
                addToCartProduct[data.id] = {
                    id: data.id,
                    title: data.name,
                    qty: qty,
                    totalAmt: (qty * price),
                    image,
                    size
                };

        } else
            addToCartProduct[data.id] = {id: data.id, title: data.name, qty: 1, totalAmt: (1 * price), image, size};

        console.log(addToCartProduct);

        localStorage.setItem('cartItem', JSON.stringify(addToCartProduct));

        dispatch({
            type: 'ADD_TO_CART',
            payload: {cart: addToCartProduct}
        });


        cb({});
    }
};

export const setCartItems = (data) => {
    return (dispatch, props) => {
        addToCartProduct = data;
        dispatch({
            type: 'ADD_TO_CART',
            payload: {cart: data}
        });
    };
};

export const removeCart = (data) => {
    return (dispatch, props) => {
        delete addToCartProduct[data.id];

        localStorage.setItem('cartItem', JSON.stringify(addToCartProduct));
        dispatch({
            type: 'ADD_TO_CART',
            payload: {cart: addToCartProduct}
        });
    }
};


export const getUserDetail = (cb) => {
    return (dispatch, getState, props) => {
        console.log("getState()", getState())
        if (getState().loginReducer.user)
            cb(getState().loginReducer.user);
        else
            cb({})
    };
};



