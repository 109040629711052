import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer from "./rootReducer";
import {logger} from 'redux-logger';

let middleware = applyMiddleware(thunk);
if (process.env.NODE_ENV === 'development') {
    middleware = composeWithDevTools(applyMiddleware(thunk, logger));
}
const store = createStore(
    rootReducer,
    middleware
);
export default store;