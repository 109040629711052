import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {removeCart, setCartItems} from "../../home/home.action";
import {Link, withRouter} from "react-router-dom";
import NavLink from "./nav-link";
import Config from '../../../helper/Config'
import CommonHelper from "../../../helper/CommonHelper";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: []
        };
        this.logout = this.logout.bind(this)
    }

    componentDidMount() {
        let cartList = JSON.parse(localStorage.getItem('cartItem'));
        if (cartList && Object.keys(cartList).length > 0)
            this.props.addToCart(cartList);

      /*  CommonHelper.get('getCategory', (status, response) => {
            this.setState({category: response.data.category});
        });*/
    }


    removeCartItem(item) {
        console.log(item);
        this.props.removeCart(item)
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        Config.conf.user = {};
        Config.conf.token = '';
        this.props.logoutAction();
        this.props.history.push('/');
        this.forceUpdate();
    }


    render() {
        let cartList = '';
        let total = 0, qty = 0;
        if (this.props.cart.cart) {
            let cartValues = Object.values(this.props.cart.cart);
            cartList = cartValues.map((item) => {
                total += item.totalAmt;
                qty += parseInt(item.qty);
                return (
                    <div key={item.id} className="ps-cart-item">
                        <a className="ps-cart-item__close" href="javascript:void(0);"
                           onClick={() => this.removeCartItem(item)}/>
                        <div className="ps-cart-item__thumbnail">
                            <a href="#"/>
                            <img
                                src={item.image}/>
                        </div>
                        <div className="ps-cart-item__content">
                            <a className="ps-cart-item__title" href="#">{item.title}</a>
                            <p><span>Quantity:<i>{item.qty}</i></span><span>Total:<i>£{item.totalAmt}</i></span>
                            </p>
                        </div>
                    </div>);
            });
        }
        const params = new URLSearchParams(this.props.location.search);
      /*  const catList = this.state.category.map((cat) => {
            return <NavLink className={(params.get('category') === cat.slug) ? 'menu-active' : ''} key={cat.id}
                            to={'/product-filter?category=' + cat.slug + '&sort=&price='}>{cat.name}</NavLink>;
        });*/

        let startOverUrl = Config.conf.site_url;


        return (
            <div>
                <div className="two"/>
               {/*<header id={'header-outer'} className="header_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2">
                                <Link to={'/'}>
                                    <div className="logo">
                                        <img src="./images/footerlogo.png"/>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-10">
                                <div className="hover">
                                    <button className="btn submenus">
                                        <i className="fa fa-angle-double-down" aria-hidden="true"/>
                                    </button>
                                    <div className="dropdown-menus">
                                        <a className="link-menu"
                                           href={startOverUrl + 'apparel'}>Apparel</a>
                                        <a className="link-menu"
                                           href={startOverUrl + 'defense'}>Defense</a>
                                    </div>
                                </div>
                                <div className="navigation" id="mySidenav">
                                    <a className="closebtn"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           window.closeNav()
                                       }}>×</a>
                                    <ul>
                                     
                                        <NavLink to={'/about-us'}>About Us</NavLink>
                                        <NavLink to={'/capabilities'}>Capabilities</NavLink>
                                        <NavLink to={'/careers'}>Opportunities</NavLink>
                                        <NavLink to={'/e-Catalog'}>E Catalog</NavLink>
                                        <NavLink to={'/news-feed'}>Newsroom</NavLink>
                                        <NavLink to={'/contact-us'}>Contact</NavLink>


                                    </ul>
                                </div>
                                <span className="toggal" onClick={() => window.openNav()}>
                                    ☰
                                </span>
                            </div>
                        </div>
                    </div>
                </header>*/}

 <header className='header'>
         <div className="top-head">
            <div className="container">
               <div className="header-top">
                  <div className="col-md-3">
                     <span className="label-year">Established <span>2008</span></span>
                  </div>
                  <div className="col-md-6 logo">
                  <Link to={'/'}  ><img src="images/logo.png" /></Link> 
                  </div>
                  <div className="col-md-3 right-info">
                     <ul className="social-media">
                        <li><a href="https://www.facebook.com/aspettoinc" target={'blank'}><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://www.instagram.com/aspetto" target={'blank'}><i className="fab fa-instagram"></i></a></li>
                        <li><a href="https://www.twitter.com/aspettoinc" target={'blank'}><i className="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/aspettoinc" target={'blank'}><i className="fab fa-linkedin"></i></a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div className="menu-bar">
          
            <div className="container">
               <nav className="navbar navbar-expand-lg">
               <Link to={'/'}  className="navbar-brand d-block d-sm-none d-md-none"><img src="images/logo.png" /></Link>

                  <button className="navbar-toggler" onClick={() => window.openNav()}>
            <svg style={{enableBackground: 'new 0 0 512 512'}} version="1.1" viewBox="0 0 512 512" width="512px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g id="_x37_20-_menu__x2C__ui__x2C__user_interface__x2C_"><g><line style={{fill: 'none', stroke: '#000000', strokeWidth: '13.4167', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '2.6131'}} x1="26.7" x2="486.25" y1="105.643" y2="105.643" /><line style={{fill: 'none', stroke: '#000000', strokeWidth: '13.4167', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '2.6131'}} x1="26.7" x2="486.25" y1="256.511" y2="256.511" /><line style={{fill: 'none', stroke: '#000000', strokeWidth: '13.4167', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '2.6131'}} x1="26.7" x2="486.25" y1="407.312" y2="407.312" /></g></g><g id="Layer_1" /></svg>
                  </button>

                  <div className=" navigation navbar-collapse justify-content-center" id="mySidenav">
                  <a className="closebtn"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           window.closeNav()
                                       }}>
                                               <svg id="Layer_1" style={{enableBackground: 'new 0 0 64 64'}} version="1.1" viewBox="0 0 64 64" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g><g id="Icon-Close" transform="translate(381.000000, 231.000000)"><polyline className="st0" id="Fill-16" points="-370.7,-174.7 -373,-177 -327,-223 -324.7,-220.7 -370.7,-174.7    " /><polyline className="st0" id="Fill-17" points="-327,-174.7 -373,-220.7 -370.7,-223 -324.7,-177 -327,-174.7    " /></g></g></svg>
                                       </a>
          
                     <ul className="navbar-nav">
                        <li className="nav-item">

                        <Link to={'/'} className="nav-link" data-hover="Home">Home</Link>
                        </li>
                        <li className="nav-item">
                        <Link to={'/about-us'} className="nav-link" onClick={() => window.closeNav()}>About Us</Link>
                        </li>
                        <li className="nav-item">
                         <Link to={'/technical'} className="nav-link" onClick={() => window.closeNav()}>tech</Link>
                        </li>
                        <li className="nav-item">
                        <Link to={'/tactical'} className="nav-link" onClick={() => window.closeNav()}>Tactical</Link>
                        </li>
                        <li className="nav-item">
                        <Link to={'/labs'} className="nav-link" onClick={() => window.closeNav()}>Labs</Link>
  
                        </li>
                        <li className="nav-item">
                         <Link to={'/digital-media'} className="nav-link" onClick={() => window.closeNav()}>Digital Media</Link>
                        </li>
                        {/* <li className="nav-item">
                         <Link to={'/e-Catalog'} className="nav-link">E Catalog</Link>
                          </li>*/}
                        <li className="nav-item">
                        <Link to={'/news-feed'} className="nav-link" onClick={() => window.closeNav()}>Newsroom</Link>
                        </li>
                        <li className="nav-item">
                         <Link to={'/contact-us'} className="nav-link" onClick={() => window.closeNav()}>Contact Us</Link>
                        </li>
                       
                     </ul>
                  </div>
               </nav>
            </div>
         </div>
      </header>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cartReducer,
    user: state.loginReducer,
});
const mapDispatchToProps = dispatch => ({
    removeCart: (payload) => dispatch(removeCart(payload)),
    addToCart: (payload) => dispatch(setCartItems(payload)),
    logoutAction: () => {
        dispatch({type: 'LOGOUT', payload: {}})
    }
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
