import React, {Component} from 'react';
import './team.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class TeamPage extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <div className="abouthding">
        <div id="owl-demo3" className="owl-carousel owl-theme topslider">
                    <div className="item">
                        <img src="./images/team.jpg" alt="The Last of us"/>
                   
                 
                
                    </div>
                </div>
                <section className="bggray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="" data-wow-duration="1.3s">
                                  Aspetto Key Team  <span>     Players</span>
                                </h2>
                                <p className="" data-wow-duration="1.6s">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                            </div>
                        </div>
                    </div>
                </section>
				
				
				
				
				<section className="team-page-content-area">
    <div className="container">
        <div className="row reorder-xs justify-content-between ">
            <div className="col-lg-6">
                <div className="left-content-area">
				
                    <h4 className="title">Abbas Haider</h4>
					<p><span className="h9">Co-Founder/CEO/President </span></p>
                    <p>Abbas Haider, included on the impressive Forbes 30 Under 30 Class of 2018 list, has been an entrepreneur for many years. From founding an import and export company to establishing a multi-million dollar brand, Abbas' business experience is wide and varied. He is a well-respected speaker and has given speeches at many business-related conferences, including TEDx, Forbes Summit, and OPEN DC. In addition, he has served on the executive advisory board at the University of Mary Washington and was awarded the 2018 Young Business Alumni Award at the university. Abbas was also included in the Maverick Pac’s Future 40 Class of 2019. </p>
                    <p>Abbas' company, Aspetto, was founded in 2008 and focuses on producing protective service equipment offerings such as cybersecurity/IT services, logistics, ballistics equipment, and other items produced to help the government and private companies achieve their multi-faceted defense-related goals. Abbas’s work has been featured via a wide array of media platforms, including Forbes, Esquire, Newsweek, CNBC, and other prominent media channels.</p>   
                
                </div>
            </div>
            <div className="col-lg-5">
                <div className="right-content-area">
                    <div className="img-wrapper">
                        <img src="./images/ceo.png" alt="chief engineer"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="full-row bggray" >
		<div class="container">

			<div class="row margin-top-15 ">
<div id="owl-demo" class="owl-carousel owl-theme">
 
 
 
   <div class="item">
   
  <div class="col-lg-12 row">
  <div class="col-md-4 text-center">
  <div class="team_img">
  <img  src="./images/team2.jpg" alt="" />
  </div>
  
  </div>
    <div class="col-md-8">
	<div class="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="700ms">
						 <h3 class="title">
						 <span>Robert Davis,  </span><br/> 
						 <span className="position"> Co-Founder/COO</span>
					</h3>
						
						<div class="text-area">
							<p>Robert Davis is the co-founder and COO of Aspetto and joins business partner Abbas on theForbes Magazine 30 Under 30 list for Manufacturing & Industry. Robertis in charge of overseeing all manufacturing operations as well as handling research and development duties.</p>
<p>An alumnus of the University of Mary Washington (UMW) School of Business in Virginia, Robert was selected to the UMW College of Business Alumni Panel and also the executive advisory board. A recipient of the Young Business Alumni Award, Robert has been recognized for his many business contributions to the Aspetto brand name. </p>
<p>
Robert is also well-known on the professional speaker circuit. Some of the business conferences where Robert has been a featured speaker include TEDx, Forbes Summit, and other large audience business conferences. Taking his in-depth knowledge of the industry and using it to encourage and educate the masses is what Robert strives to do and ultimately accomplishes.
</p>
						</div>
						
					</div></div>
  </div>
  </div>
 
 
  
  
    <div class="item">
  <div class="col-lg-12 row">
  <div class="col-md-4 text-center">
  <div class="team_img">
 <img  src="./images/team2.jpg" alt="" />
  </div>
  
  </div>
    <div class="col-md-8"><div class="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="700ms">
						 <h3 class="title"><span>Walter Clarke, </span>
						 <br/> <span className="position">VP of Government Sales</span></h3>
						
						<div class="text-area">
							<p >With more than 20 years in the sales, finance and human resources industries, Walter Clarke offers a well-rounded resume of experience suited to handle all areas of his role as vice president of government sales.
</p>
<p>Walter uses his 10+ years of defense service and protective gear knowledge to address all aspects of his government sales position with Aspetto. 

</p>
						</div>
						
					</div></div>
  </div>
  </div>
 
 
  
  
  
    <div class="item">
  <div class="col-lg-12 row">
  <div class="col-md-4 text-center">
  <div class="team_img">
 <img  src="./images/team2.jpg" alt="" />
  </div>
  
  </div>
    <div class="col-md-8"><div class="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="700ms">
						 <h3 class="title"><span>Tod Nickles, </span><br/> <span className="position">  Investor/Board of Advisors</span></h3>
						
						<div class="text-area">
							<p >Tod Nickles, with over 30 years of experience in the United States government contracting and supply chain industries, uses his business acumen to fulfill his role as investor and board of advisors member with Aspetto. Tod is the current CEO of Alliance Project Services, a logistics company in Virginia. A veteran and businessman, Tod has a great deal of experience in overseas travel and deployment for government officials.His management of personnel logistics support is expansive, having supported more than 10,000 individuals over five years with dollar values in excess of $2 billion USD.Tod uses his expansive logistics knowledge to add to the impressive work of the Aspetto team. 

</p>
						</div>
						
					</div></div>
  </div>
  </div>
 
   
  
    <div class="item">
  <div class="col-lg-12 row">
  <div class="col-md-4 text-center">
  <div class="team_img">
 <img  src="./images/team2.jpg" alt="" />
  </div>
  
  </div>
    <div class="col-md-8"><div class="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="700ms">
						 <h3 class="title"><span>Dr. Pete Johnson,    </span><br/> <span  className="position">Board of Advisors</span></h3>
						
						<div class="text-area">
							<p>With 31+ years of military and commercial strategic planning experience and an expansive background in engineering, marketing, business management, and human resources, Dr. Pete Johnson provides well-rounded expertise in his role on the board of advisors. As CEO of a strategic planning company, Pete knows the intricacies of proper business strategies and planning and how to help government officials and company management achieve their individual goals. </p>
						</div>
						
					</div></div>
  </div>
  </div>  
  
  
    <div class="item">
  <div class="col-lg-12 row">
  <div class="col-md-4 text-center">
  <div class="team_img">
 <img  src="./images/team2.jpg" alt="" />
  </div>
  
  </div>
    <div class="col-md-8"><div class="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="700ms">
						 <h3 class="title"><span>Michael Morehouse,    </span><br/> <span  className="position">Investor/Board of Advisors</span></h3>
						
						<div class="text-area">
							<p>Possessing over 30 years of experience with building and running successful companies, Michael Morehouse knows how to lead and help others properly run their companies and expand their business. Well-versed in promoting business growth and acquisition strategies, Michael serves on the board of advisors with this important acumen in place.  

</p>
						</div>
						
					</div></div>
  </div>
  </div>
 
 
  
  
 
 
 
</div>
			</div>
			
			

	
		</div>
	</section>


             

            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamPage))