import React, {Component} from 'react';
import './logistics.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class LogisticsPage extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <div>
                <div id="owl-demo" className="owl-carousel owl-theme topslider  inner-banner">
                    <div className="item">
                        <img src="./images/Logistics1.jpg" alt="The Last of us"/>
                        <div className="slidercontent">
                            <h2 className="wow fadeInLeft" data-wow-duration="1.3s ">
                                <br/><span>Logistics</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <div className=" row">
                                    <div className="  px-3 px-lg-5  align-self-center">
                                        <h2><span>Logistics</span></h2>
                                        <div className="inner_image">
                                            <img className="w-100" src="./images/img-5.jpg" alt=" " title/>
                                        </div>
                                        
										
										
										<h4>Global Door to Door Logistics</h4>
										<p>Aspetto offers logistics services worldwide. From remote to hostile locations and beyond, your logistics needs are in good hands with Aspetto handling all of the finer details. The Aspetto team has the multi-disciplinary skills needed to help you achieve all of your occupational goals. With global capabilities and hands-on experience in select business sectors, you can rest easy knowing that Aspetto is in charge of your logistics needs and won't stop until the job is done. </p>
										<p>
Aspetto is an industry leader in global logistics services offering door to door, 3PL and Just in Time logistics. This isachieved by utilization of multiple, highly skilled freight forwarders and customs brokers and subsequentmanagement of elected forwarders to insure best possible rates for all transport modes both CONUS & OCONUS.An integral aspect of Aspetto’s logistics services is complete visibility within the supply chain. Aspetto works with serviceproviders, carriers and shipping lines to be able to provide real time shipment status at any point throughout thesupply chain so our clients can schedule and adjust their activities, as needed, around accurate material arrivaldates.</p>
<p>
Aspetto also provides the following services as part of the logistics suite of services: • Negotiation of contract rates for all road, air and ocean shipments</p>
<ul>
<li>Spot rate quotes for project move cargo which are large volume / quantity, out of gauge (weight and ordimension) with remote location or impractical to ship through our consolidation warehouses
</li><li> Coordination with our clients for shipment modes for all ordered materials
</li><li> QA/QC materials check, Striping, pick and pack, repack and consolidation of materials at APS consolidationwarehouses for both ocean and air shipments enabling material flexibility within the supply chain. • Material management and inventory control for warehouse materials
</li><li> Material tracking from order placement, transit and delivery to end location</li></ul>
<p>
Labor Supply Management</p>
<ul>
<li>Database with over ten thousand vetted resumes, OCN/EXPATS 
</li><li>	Rapid Deployment
</li><li>	Internet based electronic timekeeping system</li></ul>
<p>Supply Chain Management
</p>
<ul>
<li>	Internet based procurement system (P2P)
</li><li>	Over 500 Registered Suppliers
</li><li>	Custom reporting to capture clients KPI’s</li></ul>
<p>Logistic Services
</p>
<ul>
<li>	Global Door to Door Services
</li><li>	Uses Network of highly skilled Freight Forwarders / Customs Brokers
</li><li>	Experiences in FAR export Requirements</li></ul>

										
										
										
										
										
										
                                        <button className="btn btn-danger">
                                           View Full Capability Statement
                                        </button>
                                    </div>
                                </div>
                            </div>
                           <div class="col-lg-3" >
					<div class="aside <!--bggray---> wow fadeInUp" data-wow-duration="1.3s">
					
					
					           <div class="quatto">
                    <span>Partner with Aspetto</span>
                </div>
				 <img className="w-100 mb-3" src="./images/partner.jpg" alt title/> 
				
                <aside class="secondary">

                    <p>Aspetto is always interested in building strategic relationships with other companies who have similar values, ethics, and missions. Does this sound like something your company might be interested in?</p>


                    <form action="/action_page.php">

                        <div class="form-group">

                            <input type="email" class="form-control" placeholder="Full Name: *" id="email" />
                        </div>
                        <div class="form-group">

                            <input type="email" class="form-control" placeholder="Title: *"
                                   id="email"/>
                        </div>
						 <div class="form-group">

                            <input type="email" class="form-control" placeholder="Company Name: *"
                                   id="email"/>
                        </div>
						 <div class="form-group">

                            <input type="email" class="form-control" placeholder="Company Website: *"
                                   id="email"/>
                        </div>
                        <div class="form-group">

                            <input type="email" class="form-control" placeholder="Email Address: *" id="email"/>
                        </div>
                        <div class="form-group">

                            <input type="tel" placeholder="Phone: *" class="form-control"/>
                        </div>
						
						
						<div class="new">
  <form> 
	 
  <p class="become_radio">
  <span class="become_label">Become a</span>
   <input type="radio" id="test1" name="radio-group" checked/>
    <label for="test1">Supplier</label>
    <input type="radio" id="test2" name="radio-group"/>
    <label for="test2">Distributor</label>
  </p>
   
  </form>
</div>
           <div class="form-group text-center">
                        <button type="submit" class="btn ">Submit</button>
						</div>
                    </form>
	           <div class="quatto">
                    <span>Upcoming Events</span>
					
					
                </div>
				<div id="owl-demo" className="owl-carousel owl-theme topslider">
                    <div className="item">
                        <img src="./images/event.jpg" class="mb-3" alt="The Last of us"/>
                      
              
                     <p>Find out more about Aspetto's offerings by attending one of the company's upcoming events. Learn about the services Aspetto provides and find out how you can partner with our dynamic and professional team.</p>
                    </div>
					
					                <div className="item">
                        <img src="./images/event.jpg" class="mb-3" alt="The Last of us"/>
                       <p>Find out more about Aspetto's offerings by attending one of the company's upcoming events. Learn about the services Aspetto provides and find out how you can partner with our dynamic and professional team.</p>

              
                    
                    </div>
                </div>
           
                </aside>
				
						
					</div>
					</div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogisticsPage))