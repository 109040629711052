import React, {Component} from 'react';
import './product-details.scss'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import CommonHelper from "../../helper/CommonHelper";
import {addToCart, getUserDetail} from "../home/home.action";
import SimpleReactValidator from "simple-react-validator";
import {TitleComponent} from "../../components/title.component";


const FilterComponent = ({category, queryString, active, type, fieldName}) => {
    const categoryList = category.map((cat) => {
        return (
            <li key={cat.id} className={active[type] == cat[fieldName] ? 'active' : ''}>
                <a href={'javascript:void(0);'}
                   onClick={() => queryString(type, cat[fieldName])}>
                    {(fieldName == 'sin') ? cat['sinName'] : cat[fieldName]}
                </a>
            </li>)
    });
    return (
        <ul className="stock">
            <li className={active[type] == 'all' ? 'active' : ''}><a onClick={() => queryString(type, 'all')}
                                                                     href="javascript:void(0);">All</a></li>
            {categoryList}
        </ul>
    )
};

class ProductDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: {},
            relatedProduct: [],
            qty: 0,
            selectedSize: '',
            product_id: '',
            country: [],
            quote: {
                product_id: '',
                first_name: '',
                last_name: '',
                phone: '',
                country: '',
                email: '',
                are_you: 'Federal',
                quote_msg: ''
            },
            allReview: [],
            brand: [],
            category: [],
            query: {}
        };
        this.handleChanges = this.handleChanges.bind(this);
        this.handleChangesReview = this.handleChangesReview.bind(this);
        this.quoteSubmit = this.quoteSubmit.bind(this);
        this.filterFunc = this.filterFunc.bind(this);
        this.onAddressChanged = this.onAddressChanged.bind(this);
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.getProduct();
    }

    getProduct() {
        this.props.getUserDetailAction((user) => {
            const {slug} = this.props.match.params;
            CommonHelper.get(('getSingleProductDefence/') + slug, (status, response) => {
                this.setState({
                    product: response.data.product,
                    quote: {product_id: response.data.product.id},
                    product_id: response.data.product.id,
                    country: response.data.countries,
                    brand: response.data.brand,
                    category: response.data.category
                });

            });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.cart.cart && prevProps.cart.cart) {
            if (this.props.cart.cart[this.state.product.id] && this.state.qty === 0) {
                this.setState({qty: this.props.cart.cart[this.state.product.id].qty});
            }
        }
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.getProduct();
        }
    }

    handleChanges(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    handleChangesReview(event) {
        this.setState({quote: {...this.state.quote, [event.target.name]: event.target.value}})
    }

    quoteSubmit(event) {
        event.preventDefault();
        if (this.validator.allValid()) {
            CommonHelper.post('needQuote', this.state.quote, (status, response) => {
                if (response.status) {
                    this.setState({
                        quote: {
                            first_name: '',
                            last_name: '',
                            phone: '',
                            country: '',
                            email: '',
                            are_you: 'Federal',
                            quote_msg: ''
                        }
                    })
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    filterFunc(type, val) {
        let queryS = '?category=&search=&page=1&' + type + '=' + val;
        this.props.history.push('/e-Catalog' + queryS)
    }

    onAddressChanged(e) {
        this.setState({
            quote: {
                ...this.state.quote,
                are_you: e.currentTarget.value
            }
        });
    }

    render() {
        const country = this.state.country.map((country) => {
            return (
                <option key={country.id} value={country.name}>{country.name}</option>
            );
        });
        let {product} = this.state;
        return (
            <div className={'component-product-details '}>
                {(Object.values(product).length > 0) &&
                <div className="">
                    <TitleComponent title={product.prod_name}/>
  
                    <div className="ps-shopping-cart mt-4">
                        <div className="container">
                            <div className={'row justify-content-center'}>
                                {/*<div className={'col-md-3'}>
                                <div className="left-asidebar">
                                    <div className="filterleft">
                                        <div className="tital-cat">Brand</div>
                                        <FilterComponent
                                            active={this.state.query}
                                            category={this.state.brand}
                                            queryString={this.filterFunc}
                                            type={'brand'}
                                            fieldName={'manufacturer_name'}
                                        />

                                        <div className="tital-cat">Category</div>
                                        <FilterComponent
                                            active={this.state.query}
                                            category={this.state.category}
                                            queryString={this.filterFunc}
                                            type={'category'}
                                            fieldName={'sin'}
                                        />
                                    </div>
                                </div>
                                </div> */}
                                <div className={'col-md-10'}>
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 col-md-12">
                                            <img className="w-100 catlog-thumb" src={product.full} alt=""/>
                                        </div>
                                        <div className="col-lg-7 col-md-12">
                                            <div className="ps-product__info">
                                                <h4>{product.prod_name}</h4>
                                                <h5>Commercial Price - <span>${product.commercial_price}</span></h5>
                                                {/*<div className="ps-product__desc"
                                                     dangerouslySetInnerHTML={{__html: product.prod_desc}}/>*/}
                                            </div>
                                         


                                            {/* Tab panes */}
                                            <div className="product-contents">
                                                    <h4>Description</h4>
                                                    <p dangerouslySetInnerHTML={{__html: product.prod_desc}}/>
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        {product.size && <tr>
                                                            <td scope="col">Size</td>
                                                            <td><a
                                                                href="javascript:void(0);">{product.size}</a>
                                                            </td>
                                                        </tr>}
                                                        {product.color && <tr>
                                                            <td scope="row">Color</td>
                                                            <td><a
                                                                href="javascript:void(0);">{product.color}</a>
                                                            </td>
                                                        </tr>
                                                        } {product.typ && <tr>
                                                            <td scope="row">Type</td>
                                                            <td><a
                                                                href="javascript:void(0);">{product.typ}</a>
                                                            </td>
                                                        </tr>
                                                        }
                                                        </tbody>
                                                    </table>

                                                    <h4>Additional Information</h4>
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                        <tr>
                                                            <td scope="col">Brand</td>
                                                            <td><a
                                                                href="javascript:void(0);">{product.manufacturer_name}</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MFR PART NO</td>
                                                            <td><a
                                                                href="javascript:void(0);">{product.mfr_part_no}</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">DEALER PART NUMBER</td>
                                                            <td><a
                                                                href="javascript:void(0);"> {product.dealer_part_no}</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">UPC-A</td>
                                                            <td><a
                                                                href="javascript:void(0);"> {product.upc_a}</a>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="left-centertab mt-5">

                                        <h4 className="">Need A Quote?</h4>
                                        <p>
                                            <strong>Products are available for DOD, Federal, State, and Local Government
                                                Agency
                                                ordering.</strong> 
                                            <span
                                                className="red"><strong> For personal orders, Aspetto requires a background check. </strong></span>
                                            <strong>Contact us for quotes, info, and ordering.</strong>
                                        </p>

                                        <div className="contactleft pt-3">
                                            <h4>Add Your Quote</h4>
                                            <form className='contact-form-box' id="contact-form" onSubmit={this.quoteSubmit}>
                                                <div className="messages"/>
                                     
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="form_name">First Name </label>
                                                                <input id="form_name"
                                                                       type="text"
                                                                       name="first_name"
                                                                       value={this.state.quote.first_name || ''}
                                                                       className="form-control"
                                                                       placeholder="Please enter your name *"
                                                                       onChange={this.handleChangesReview}
                                                                />
                                                                {this.validator.message('first name', this.state.quote.first_name, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="form_name">Last Name </label>
                                                                <input id="form_name"
                                                                       type="text"
                                                                       name="last_name"
                                                                       value={this.state.quote.last_name || ''}
                                                                       className="form-control"
                                                                       placeholder="Please enter your name *"
                                                                       onChange={this.handleChangesReview}
                                                                />
                                                                {this.validator.message('last_name', this.state.quote.last_name, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="form_email">
                                                                    Email </label>
                                                                <input id="form_email"
                                                                       type="email"
                                                                       name="email"
                                                                       value={this.state.quote.email || ''}
                                                                       className="form-control"
                                                                       placeholder="Please enter your email *"
                                                                       onChange={this.handleChangesReview}
                                                                />
                                                                {this.validator.message('email', this.state.quote.email, 'required|email')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Phone (xxx) xxx-xxxx </label>
                                                                <input id="form_email"
                                                                       type="text"
                                                                       name="phone"
                                                                       value={this.state.quote.phone || ''}
                                                                       className="form-control"
                                                                       placeholder="Please enter your phone *"
                                                                       onChange={this.handleChangesReview}
                                                                />
                                                                {this.validator.message('phone', this.state.quote.phone, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Country</label>
                                                                <select
                                                                    className="form-control"
                                                                    name="country"
                                                                    onChange={this.handleChangesReview}
                                                                    value={this.state.quote.country || ''}>
                                                                    <option value={''}>Select Country</option>
                                                                    {country}
                                                                </select>
                                                                {this.validator.message('country', this.state.quote.country, 'required')}
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div className="form-group">


                                                                <label>Are You </label>
                                                                <p className="become_radio">

                                                                    <input
                                                                        type="radio"
                                                                        id="Federal"
                                                                        name="radio-group"
                                                                        value={'Federal'}
                                                                        checked={this.state.quote.are_you === 'Federal'}
                                                                        onChange={this.onAddressChanged}
                                                                    />
                                                                    <label htmlFor="Federal">Federal</label>


                                                                    <input
                                                                        type="radio"
                                                                        id="State"
                                                                        name="radio-group"
                                                                        value={'State'}
                                                                        checked={this.state.quote.are_you === 'State'}
                                                                        onChange={this.onAddressChanged}
                                                                    />
                                                                    <label htmlFor="State">State</label>

                                                                    <input
                                                                        type="radio"
                                                                        id="Local"
                                                                        name="radio-group"
                                                                        value={'Local'}
                                                                        checked={this.state.quote.are_you === 'Local'}
                                                                        onChange={this.onAddressChanged}
                                                                    />
                                                                    <label htmlFor="Local">Local</label>

                                                                    <input
                                                                        type="radio"
                                                                        id="Foreign"
                                                                        name="radio-group"
                                                                        value={'Foreign'}
                                                                        checked={this.state.quote.are_you === 'Foreign'}
                                                                        onChange={this.onAddressChanged}
                                                                    />
                                                                    <label htmlFor="Foreign">Foreign</label>

                                                                    <input
                                                                        type="radio"
                                                                        id="Civilian"
                                                                        name="radio-group"
                                                                        value={'Civilian'}
                                                                        checked={this.state.quote.are_you === 'Civilian'}
                                                                        onChange={this.onAddressChanged}
                                                                    />
                                                                    <label htmlFor="Civilian">Civilian</label>
                                                                </p>
                                                                {this.validator.message('are_you', this.state.quote.are_you, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Requesting a Quote For </label>
                                                                <textarea
                                                                    rows="4"
                                                                    className="form-control"
                                                                    cols="50"
                                                                    value={this.state.quote.quote_msg || ''}
                                                                    name={'quote_msg'}
                                                                    onChange={this.handleChangesReview}
                                                                />
                                                                {this.validator.message('quote_message', this.state.quote.quote_msg, 'required')}
                                                            </div>
                                                        </div>
    
                                                    </div>
                                               
                                                     
                                                        <div className="col-md-12 text-center mt-3">
                                                            <input type="submit"
                                                                   className="btn btn-success btn-send"
                                                                   defaultValue="Submit Reviews"/>
                                                        </div>
                                                  
                                                
                                            </form>
                                        </div>


                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cartReducer,
    wishListData: state.productReducer
});
const mapDispatchToProps = dispatch => ({
    addToCartAction: (payload, type, cb) => dispatch(addToCart(payload, type, cb)),
    wishListAction: (payload) => dispatch({type: 'WISH_LIST', payload}),
    getUserDetailAction: (cb) => {
        dispatch(getUserDetail(cb))
    }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage))
