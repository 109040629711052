import React, {Component} from 'react';
import './home.page.scss';
import connect from "react-redux/es/connect/connect";
import NavLink from "../_layout/template-1/nav-link";
import CommonHelper from "../../helper/CommonHelper";
import {getUserDetail} from "./home.action";
import {Link, withRouter} from "react-router-dom";
import QuotePopup from "../../components/quote-popup";

class HomePage extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            country: [],
        };
    }

    getDefenceCat() {
        CommonHelper.get('getDefenceCat', (status, response) => {
            if (this._isMounted) {
                this.setState({categories: response.data.category})
            }
        });
    }

  
  
    componentDidMount() {



        this._isMounted = true;
        this.getDefenceCat();


        CommonHelper.get('getCountry', (status, res) => {
            this.setState({country: res.data.countries})
        });



        const script = document.createElement("script");
        script.src = "js/custom.js";
        script.async = true;
        document.body.appendChild(script);

    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    showModal() {
        window.$('#myModal5').modal({backdrop: 'static'});
    }


    render() {
        return (

            
            <div className="component-home-page">

<section className="aspetto-intro">
        <div className="container-fluid">
          <div className="intro-header">
            <div className="intro-logo">
              <img src="images/logo.png" alt="logo" />
             {/*  <h6> <span id="typed" /></h6>*/}
			     <h6 className="">Tech to Tactical - We've Got You Covered</h6>
            </div>
            <div className="intro-date-area">
              <div className="custom-shape"><img src="images/flag-bg.png" /></div>
              <div className="date-time-content">
                {/* <div class="flags"> <img src="images/us-flag.png"></div>*/}
                <div className="date-time-area">
                 <Link to={'/news-feed'} className="day-type">Latest News</Link>
                 <Link to={'/careers'} className="day-type">Careers</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="intro-wrapper">
          <div className="container">
            <div className="intro-jarvis">
              <div className="jarvis-item">
                <div className="jarvis-nav for-desktop">
                  <ul>
                       <NavLink to={'/about-us'} id="defense-btn" className="nav-items-jarvis">About Us</NavLink>
                       <NavLink to={'/technical'} id="apparel-btn" className="nav-items-jarvis">tech</NavLink>
                       <NavLink to={'/tactical'} id="engineering-btn" className="nav-items-jarvis">Tactical</NavLink>
                       <NavLink to={'/labs'} id="its-btn" className="nav-items-jarvis">Labs</NavLink>
                       <NavLink to={'/digital-media'} id="digital-btn" className="nav-items-jarvis">Digital Media</NavLink>
                       <NavLink to={'/contact-us'} id="contacts-btn" className="nav-items-jarvis">Contact us</NavLink>
                  </ul>
                </div>
                <div className="jarvis-nav for-mobile">
                  <ul>
                       <NavLink to={'/about-us'}  className="nav-items-jarvis">About Us</NavLink>
                       <NavLink to={'/technical'}  className="nav-items-jarvis">tech</NavLink>
                       <NavLink to={'/tactical'}  className="nav-items-jarvis">Tactical</NavLink>
                       <NavLink to={'/labs'}  className="nav-items-jarvis">Labs</NavLink>
                       <NavLink to={'/digital-media'}  className="nav-items-jarvis">Digital Media</NavLink>
                       <NavLink to={'/contact-us'} className="nav-items-jarvis">Contact us</NavLink>
                  </ul>
                </div>
              </div>
              <div className="jarvis-item main-jarvis">
                <div className="jarvis_reactor">
                  <div className="reactor-item layer-01">
                    <img src="images/jarvis/layer1.png" />
                  </div>
                  <div className="reactor-item layer-02"> <img src="images/jarvis/layer2.png" /></div>
                  <div className="reactor-item layer-03"> <img src="images/jarvis/layer3.png" /></div>
                  <div className="reactor-item layer-04"> <img src="images/jarvis/layer4.png" /></div>
                  <div className="reactor-item layer-05"> <img src="images/jarvis/layer5.png" /></div>
                  <div className="reactor-item layer-06"> <img src="images/jarvis/layer6.png" /></div>
                  <div className="reactor-item layer-07"> <img src="images/jarvis/layer7.png" /></div>
                  <div className="reactor-item layer-08"> <img src="images/jarvis/layer8.png" /></div>
                  <div className="reactor-item layer-09"> <span className="jarvis-dymond" /></div>
                  <div className="reactor-item layer-10" id="defense-imgs"> <img src="images/jarvis/layer9.png" /></div>
                  <div className="reactor-item layer-10" id="engering-imgs"> <img src="images/jarvis/layer9-engering.png" /></div>
                  <div className="reactor-item layer-10" id="apparil-imgs"> <img src="images/jarvis/layer9-apparil.png" /></div>
                  <div className="reactor-item layer-10" id="it-imgs"> <img src="images/jarvis/layer9-it.png" /></div>
                  <div className="reactor-item layer-10" id="digi-imgs"> <img src="images/jarvis/digital-media-img.png" /></div>
                </div>
              </div>
              <div className="jarvis-item ">
                <div className="slide-content-bg">
                  <div className="hero-slider defense-conetent-box">
                    <h3>About Us</h3>
                    <p>We innovate breakthrough technologies that deliver strategic enterprise life-cycle support for the acquisition, design, deployment, and sustainment of Federal and DoD technology and life protection components. Our nation’s top agencies utilize products and services developed and supported by Aspetto. Our mission is simple, from tech to tactical – we’ve got you covered. </p>
                    
                  </div>
                  <div className="hero-slider engering-conetent-box">
                    <h3>Tactical</h3>
                    <p>We manufacture and distribute cutting-edge, high-performance tactical equipment for the U.S. military and both domestic and international law enforcement communities. Through our focus on research and development, coupled with unique systems integration capabilities and innovative technology, Aspetto Inc. has developed patented solutions for some of the highest-performing products available in the industry. </p>
                    {/*<button class="btn btn-view-more">View E-Catalog</button>*/}
                  </div>
                  <div className="hero-slider apparil-conetent-box">
                    <h3>Tech</h3>
                    <p>Our information technology division has earned a reputation for being precise, dependable, and technically superior:  from tech to tactical – we’ve got you, your infrastructure, and your data covered.</p>
                   {/*<Link to={'/technical'} className="btn btn-view-more">View More</Link>*/} 
        
                  </div>
                  <div className="hero-slider it-conetent-box">
                    <h3>Labs </h3>
                    <p>
                    We offer end-to-end engineering solutions for federal agencies and the commercial market.
                      From concept generation to mass production of products, our engineering team can complete your engineering projects no matter what the size.
                    </p>
                     {/* <Link to={'/labs'} className="btn btn-view-more">View More</Link>*/} 
                   
                  </div>
                  
                  <div className="hero-slider digi-conetent-box">
                    <h3>Digital Media</h3>
                    <p>
                    Our digital media efforts have been an integral part of the military and the first responders community. With an audience of nearly a million followers, we connect with our service members, veterans, and the first responders on a different level. Our podcast has been rated as one of the top in the world and the online newspaper keeps our community up-to-date on current affairs. 
                    </p>
                       {/*  <Link to={'/digital-media'} className="btn btn-view-more">View More</Link>*/} 
                   
                  </div>

                  <div className="hero-slider contact-conetent-box">
                    <h3>Contact Us</h3>
                    <p>
                     Aspetto team is here to serve you Monday to Friday 8am to 5pm EST. If you have any questions about our products or services, please feel free to reach out to us.
                    </p>
                  </div>				  
                  <div className="intro-custom-shape">
                    <span className="intro-ani-item" />
                    <span className="intro-ani-item" />
                    <span className="intro-ani-item" />
                    <span className="intro-ani-item" />
                    <span className="intro-ani-item" />
                    <span className="intro-ani-item" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container intro-footer">
            <div className="intro-footer-content">
              <h4>40,000+</h4>
              <p> Products &amp; Services</p>
              <Link to={'/getquote'} className="btn btn-quote-home desktop-btns">Request a Quote  </Link>
            </div>
            <div className="  intro-solial">
              <ul>
                <li><a href="https://www.facebook.com/aspettoinc"><i className="fab fa-facebook" /> <span>Facebook</span></a></li>
                <li><a href="https://www.instagram.com/aspetto"><i className="fab fa-instagram" /> <span>Instagram</span></a></li>
                <li><a href="https://www.twitter.com/aspettoinc"><i className="fab fa-twitter" /> <span>Twitter</span></a></li>
                <li><a href="https://www.linkedin.com/company/aspettoinc"><i className="fab fa-linkedin"></i> <span>Linkedin</span></a></li>
                {/*<li><a href="#"><i class="fab fa-pinterest"></i> <span>Pintrest</span></a></li>*/}
              </ul>
            </div>
          </div>
        </div>
       
      </section>
      <div className='mobqoute-btns'><Link to={'/getquote'} className="btn btn-quote-home ">Request a Quote   <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path d="M478,256,302,432l-21.21-21.2L420.6,271H34V241H420.6L280.75,101.16,302,80Z" />
                          </svg></Link></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cartReducer,
    user: state.loginReducer,
});
const mapDispatchToProps = dispatch => ({
    getUserDetailAction: (cb) => {
        dispatch(getUserDetail(cb))
    }
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage))
