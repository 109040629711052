import React, {Component} from 'react';
import Header from "./template-1/header";
import Footer from "./template-1/footer";
import connect from "react-redux/es/connect/connect";


class MainLayout extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        /* if (this.props.location.pathname != '/') {
             document.getElementById('header-outer').classList.add('headerbg')
         } else {
             document.getElementById('header-outer').classList.remove('headerbg')
         }*/
        console.log("this.props.location", this.props.location)
        if (this.props.location !== prevProps.location) {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    }

    render() {
        return (
            <div>
                {this.props.location.pathname!='/'?<Header/>:''}
                {this.props.children}
                {this.props.location.pathname!='/'?<Footer/>:''}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cartReducer
});
const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)