import React, {Component} from 'react';
import {HashRouter, Route, Switch} from "react-router-dom";
import LoginPage from "./pages/login/login.page";
import HomePage from "./pages/home";
import RegisterPage from "./pages/register";
import CartPage from "./pages/cart";
import {ProductFilterPage, ProductFilter2Page} from "./pages/product-filter";
import {connect} from "react-redux";
import MainLayout from "./pages/_layout/main";
import scriptLoader from 'react-async-script-loader';
import './app.scss'
import Config from "./helper/Config";
import PrivateComponent from "./hoc/private";
import ProductDetailsPage from './pages/product-details'
import BlogDetailsPage from './pages/blog-details'
import AboutUsPage from "./pages/about";
import TacticalPage from "./pages/tactical";
import GetquotePage from "./pages/getquote";
import TechnicalPage from "./pages/technical";
import DigitalmediaPage from "./pages/digital-media";
import LabsPage from "./pages/labs";
import TeamPage from "./pages/team";
import ECatalogPage from "./pages/ecatlog";
import PageNotFoundPage from "./pages/page-not-found";
import CareersPage from "./pages/careers";
import PastPerformancePage from "./pages/past-performance";
import ProductFilterPageComponent from "./pages/product-filter";
import CapabilitiesPage from "./pages/capabilities";
import NewsFeedPage from "./pages/news-feed";
import NewsDetailsPage from "./pages/news-details";
import ContactUsPage from "./pages/contact-us";
import ArmoredVehiclesPage from "./pages/armored-vehicles";
import CyberSecurityPage from "./pages/cyber-security";
import LogisticsPage from "./pages/logistics";
import ProtectiveSolutionsPage from "./pages/protective-solutions";
import MilitaryAviationProtectionPage from "./pages/military-aviation-protection";
import {TitleComponent} from "./components/title.component";

// withTitle function
const withTitle = ({component: Cmp, title}) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
        }

        render() {
            return (
                <React.Fragment>
                    <TitleComponent title={title}/>
                    <Cmp {...this.props} />
                </React.Fragment>
            );
        }
    };
};

class App extends Component {

    constructor(props) {
        super(props);
        let user = localStorage.getItem('user');
        if (user) {
            let userData = JSON.parse(Config.decrypt(user));
            Config.conf.user = userData;
            Config.conf.token = localStorage.getItem('token');
            this.props.loginAction(userData)
        }

    }


    render() {
        const HomePageComponent = withTitle({component: HomePage, title: 'Aspetto'});
        const RegisterPageComponent = withTitle({component: RegisterPage, title: 'Register'});
        const AboutUsPageComponent = withTitle({component: AboutUsPage, title: 'About'});
        const TacticalPageComponent = withTitle({component: TacticalPage, title: 'tactical'});
        const GetquotePageComponent = withTitle({component: GetquotePage, title: 'getquote'});
        const TechnicalPageComponent = withTitle({component: TechnicalPage, title: 'technical'});
        const DigitalmediaPageComponent = withTitle({component: DigitalmediaPage, title: 'digital media'});
        const LabsPageComponent = withTitle({component: LabsPage, title: 'labs'});
        const TeamPageComponent = withTitle({component: TeamPage, title: 'Team'});
        const CartPageComponent = withTitle({component: CartPage, title: 'Cart'});
        const OpportunitiesPageComponent = withTitle({component: CareersPage, title: 'Opportunities'});
        const PastPerformancePageComponent = withTitle({component: PastPerformancePage, title: 'Opportunities'});
        const ProductFilterPageComponentComponent = withTitle({component: ProductFilterPageComponent, title: 'Filter'});
        const CapabilitiesPageComponent = withTitle({component: CapabilitiesPage, title: 'Capabilities'});
        const ECatalogPageComponent = withTitle({component: ECatalogPage, title: 'E Catalog'});
        const ContactUsPageComponent = withTitle({component: ContactUsPage, title: 'Contact Us'});
        const NewsFeedPageComponent = withTitle({component: NewsFeedPage, title: 'Newsroom'});
        const ArmoredVehiclesPageComponent = withTitle({component: ArmoredVehiclesPage, title: 'Armored Vehicles'});
        const CyberSecurityPageComponent = withTitle({component: CyberSecurityPage, title: 'Cyber Security'});
        const LogisticsPageComponent = withTitle({component: LogisticsPage, title: 'Logistics'});
        const ProtectiveSolutionsPageComponent = withTitle({
            component: ProtectiveSolutionsPage,
            title: 'Protective Solutions'
        });
        const MilitaryAviationProtectionPageComponent = withTitle({
            component: MilitaryAviationProtectionPage,
            title: 'Military Aviation Protection'
        });


        return (
            <HashRouter>
                {/*basename={'/aspetto/front'}*/}
                <Switch>
                    {/* <Route path="/" exact component={Home2Page}/>
                    <Route path="/book-appointment" exact component={BookAppointment2Page}/>*/}
                    <MainLayout>
                        <Switch>
                            <Route path="/login" exact render={() => <LoginPage/>}/>
                            <Route path="/past-performance" exact component={PastPerformancePageComponent}/>
                            <Route path="/register" exact component={RegisterPageComponent}/>
                            <Route path="/" exact component={HomePageComponent}/>
                            <Route path="/team" exact component={TeamPageComponent}/>
                            <Route path="/careers" exact component={OpportunitiesPageComponent}/>
                            <Route path="/about-us" exact component={AboutUsPageComponent}/>
                            <Route path="/tactical" exact component={TacticalPageComponent}/>
                            <Route path="/getquote" exact component={GetquotePageComponent}/>
                            <Route path="/technical" exact component={TechnicalPageComponent}/>
                            <Route path="/digital-media" exact component={DigitalmediaPageComponent}/>
                            <Route path="/labs" exact component={LabsPageComponent}/>
                            <Route path="/cart" exact component={CartPageComponent}/>
                            {/*<Route path="/product-filter" component={ProductFilterPageComponent}/>*/}
                            <Route path="/product/:slug" exact component={ProductDetailsPage}/>
                            <Route path="/blog/:slug" exact component={BlogDetailsPage}/>
                            <Route path="/news-details/:slug" exact component={NewsDetailsPage}/>
                            <Route path="/news-feed" exact component={NewsFeedPageComponent}/>
                            <Route path="/contact-us" exact component={ContactUsPageComponent}/>
                            <Route path="/capabilities" exact component={CapabilitiesPageComponent}/>

                            <Route path="/e-catalog" exact component={ProductFilterPageComponent}/>


                            <Route path="/category/armored-vehicles" exact component={ArmoredVehiclesPageComponent}/>
                            <Route path="/category/cyber-security" exact component={CyberSecurityPageComponent}/>
                            <Route path="/category/logistics" exact component={LogisticsPageComponent}/>
                            <Route path="/category/protective-solutions" exact
                                   component={ProtectiveSolutionsPageComponent}/>
                            <Route path="/category/military-aviation-protection" exact
                                   component={MilitaryAviationProtectionPageComponent}/>


                            <Route path="*" exact component={PageNotFoundPage}/>
                        </Switch>
                    </MainLayout>

                </Switch>
            </HashRouter>
        );
    }
}

const mapStateToProps = (state) => ({
    loginA: state.loginReducer,
    cart: state.cartReducer,
});
const mapDispatchToProps = dispatch => ({
    loginAction: (payload) => dispatch({type: 'USER', payload: payload})
});


export default scriptLoader(
    [
        
    ]
)(connect(mapStateToProps, mapDispatchToProps)(App))
