import axios from 'axios';
import $ from 'jquery'
import Config from './Config';

class CommonHelper {

    constructor() {
        /* let token = localStorage.getItem('token'),
             authHeader = {Accept: 'application/json', 'Content-type': 'application/json'};
         if (token)
             authHeader = {
                 Accept: 'application/json',
                 'Content-type': 'application/json',
                 'Authorization': 'Bearer ' + token
             };*/
        let service = axios.create({
            baseURL: Config.conf.url,
            timeout: 40000,
            headers: {Accept: 'application/json', 'Content-type': 'application/json'}
        });
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
    }


    handleSuccess(response) {
    	if(document.getElementById('preloader'))
        	document.getElementById('preloader').style.display = 'none';
        if (response.data.message) {
            new window.PNotify({
                title: '',
                text: response.data.message,
                type: 'success',
                styling: 'bootstrap3',
                delay: 3000
            });
        }
        return response;
    }

    handleError = (error) => {
        if (error.response) {
            new window.PNotify({
                title: '',
                text: error.response.data.message,
                type: 'error',
                styling: 'bootstrap3',
                delay: 1500
            });
        }
        return Promise.reject(error)
    };

    get(path, callback) {
        this.preLoader(true);
        let token = localStorage.getItem('token');
        return this.service.get(path, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then(
            (response) => callback(response.status, response.data)
        ).catch(this.handleError);
    }

    patch(path, payload, callback) {
        return this.service.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => callback(response.status, response.data)).catch(this.handleError);
    }

    post(path, payload, callback) {
        this.preLoader(true);
        let token = localStorage.getItem('token');
        return this.service.request({
            method: 'POST',
            url: path,
            responseType: 'json',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: payload
        }).then((response) => callback(response.status, response.data));
    }

    upload(url, postBody, cb) {
        let formData = new FormData();

        for (let property in postBody) {
            if (postBody[property] instanceof Array) {
                for (var files in postBody[property]) {
                    formData.append(property + '[]', postBody[property][files]);
                }
            } else
                formData.append(property, postBody[property]);

        }

        return this.post(url, formData, cb);

    }

    loadScript(src) {
        var len = $('script').filter(function () {
            return ($(this).attr('src') == src);
        });
        if (len.length === 0) {
            var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            document.getElementById('script-load').appendChild(tag);
        }
    }

    preLoader(status) {
    	if(document.getElementById('preloader')){
        if (status) {
            document.getElementById('preloader').style.display = 'flex';
        } else {
            document.getElementById('preloader').style.display = 'none';
        }
    }
    }
}

export default new CommonHelper();
