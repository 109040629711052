import React, {Component} from 'react';
import './past-performance.scss'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import CommonHelper from "../../helper/CommonHelper";

class PastPerformancePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            past_per: []
        }
    }

    componentDidMount() {
        this.getPastPer();
    }

    getPastPer() {
        CommonHelper.get('pastPerformance', (status, res) => {
            this.setState({past_per: res.data.data});
        })
    }


    render() {

        return (
            <div>
                <div id="owl-demo" className="owl-carousel owl-theme topslider inner-banner">
                    <div className="item">
                        <img src="./images/armore1.jpg" alt="The Last of us"/>
         
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className=" row">
                                    {this.state.past_per.map((data) => {
                                        return  <div className="  px-3 px-lg-5  align-self-center">
                                            <h2>Armored</h2>
                                            <div className="inner_image">
                                                <img className="w-100" src="./images/img-2.jpg" alt=" "/>
                                            </div>
                                            <p> Aspetto offers a full life cycle support to its clients. From idea to
                                                prototyping to mass
                                                production, Aspetto covers all aspects of production for its clients.
                                                With
                                                the R&amp;D lab
                                                located in Fredericksburg, VA, the management has a hands-on approach
                                                with
                                                all the projects.
                                                Aspetto relies on a team of advisors whenever necessary. </p>
                                            <p>Aspetto utilizes laser cutters, Gerber automatic cutters, and automated
                                                stitch machines to
                                                make the production process more efficient. </p>
                                        </div>
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PastPerformancePage))