import React, {Component} from 'react';
import './product-filter.scss'
import connect from "react-redux/es/connect/connect";
import CommonHelper from "../../helper/CommonHelper";
import {addToCart} from "../home/home.action";
import {Link, withRouter} from "react-router-dom";
import Pagination from "react-js-pagination";
import SimpleReactValidator from "simple-react-validator";
import QuotePopup from "../../components/quote-popup";


const FilterComponent = ({category, queryString, active, type, fieldName}) => {
    const categoryList = category.map((cat) => {
        return (
            <li key={cat.id} className={active[type] == cat[fieldName] ? 'active' : ''}>
                
                <a 
                   onClick={() => queryString(type, cat[fieldName], null)}>
                    {(fieldName == 'sin') ? cat['sinName'] : cat[fieldName]}
                </a>
            </li>)
    });
    return (
        <ul className="stock">
            {/*<li className={active[type] == 'all' ? 'active' : ''}>
                <a onClick={() => queryString(type, 'all')}>All</a>
            </li>*/}
            {categoryList}
        </ul>
    )
};

class GetquotePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: [],
            brand: [],
            category: [],
            products: [],
            product_max_amt: 0,
            query: {
                brand: '',
                cat: '',
                activePage: 1
            },
            priceFilter: 1,
            searchKeyword: '',
            per_page: 0,
            total_records: 0,
            activePage: 1,
            quote: {
                product_id: '',
                first_name: '',
                last_name: '',
                phone: '',
                country: '',
                email: '',
            },
        };
        this.getBrands = this.getBrands.bind(this);
        this.filterFunc = this.filterFunc.bind(this);
        this.filterProduct = this.filterProduct.bind(this);
        this.searchProduct = this.searchProduct.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.quoteSubmit = this.quoteSubmit.bind(this);
        this.searchInputField = React.createRef();
        this.searchTimeOut = null;
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.filterProduct();
        CommonHelper.get('getCountry', (status, res) => {
            this.setState({country: res.data.countries})
        })
    }

    filterProduct() {
        const params = new URLSearchParams(this.props.location.search);

        const getPageNum = localStorage.getItem('filterPage');
        let page = (params.get('page')) ? params.get('page') : 1;

        if (getPageNum)
            page = getPageNum;

        let queryStr = '?page=' + page, activeCat = '';

        if (this.props.location.search) {
            activeCat = '?category=' + params.get('category') + '&brand=' + params.get('brand') + '&search=' + params.get('search') + '&page=' + page;
            queryStr = activeCat;
        }
        this.getBrands(queryStr);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.filterProduct();
        }
    }

    getBrands(queryString = '') {
        const params = new URLSearchParams(this.props.location.search);
        const getPageNum = localStorage.getItem('filterPage');
        let page = (params.get('page')) ? params.get('page') : 1;
        if (getPageNum)
            page = getPageNum;

        CommonHelper.get('getDefenceBrand' + queryString, (status, res) => {
            this.setState({
                brand: res.data.brand,
                category: res.data.category,
                products: res.data.products.data,
                total_records: res.data.products.total,
                per_page: res.data.products.per_page,
                activePage: parseInt(page),
                query: {brand: params.get('brand'), cat: params.get('category'), activePage: params.get('page')}
            });
        })
    }

    filterFunc(type, val, pag) {
        let queryS = '?category=&brand=&search=&page=';
        let category = this.state.query.cat;
        if (category == null) {
            category = '';
        }
        let brand = this.state.query.brand;
        if (brand == null) {
            brand = ''
        }

        let page = 1;
        localStorage.setItem('filterPage', page);


        if (type == 'brand') {
            queryS = '?category=' + category + '&brand=' + val + '&search=' + this.state.searchKeyword + '&page=' + page;
        } else if (type == 'cat') {
            queryS = '?category=' + val + '&brand=' + brand + '&search=' + this.state.searchKeyword + '&page=' + page;
        } else if (type == 'page') {
            queryS = '?category=' + category + '&brand=' + brand + '&search=' + this.state.searchKeyword + '&page=' + val;
        }

        this.props.history.push('/e-catalog' + queryS)
    }

    searchProduct(event) {
        let search = event.target.value;
        let searchField = event.target;
        this.setState({searchKeyword: search});
        clearTimeout(this.searchTimeOut);

        let category = this.state.query.cat;
        if (category == null) {
            category = '';
        }
        let brand = this.state.query.brand;
        if (brand == null) {
            brand = ''
        }

        this.searchTimeOut = setTimeout(() => {
            this.props.history.push('/e-Catalog?brand=' + brand + '&category=' + category + '&search=' + search);
            searchField.focus();
        }, 1500);
    }

    handlePageChange(pageNumber) {

        let category = this.state.query.cat;
        if (category == null) {
            category = '';
        }
        let brand = this.state.query.brand;
        if (brand == null) {
            brand = ''
        }
        let queryS = '?category=' + category + '&brand=' + brand + '&search=' + this.state.searchKeyword + '&page=' + pageNumber;

        CommonHelper.get('getDefenceBrand' + queryS, (status, res) => {
            localStorage.setItem('filterPage', pageNumber);
            this.setState({
                activePage: pageNumber,
                products: res.data.products.data,
                total_records: res.data.products.total,
                per_page: res.data.products.per_page,
            });
        });
        // this.filterFunc('page', pageNumber)
    }

    quoteSubmit(event) {
        event.preventDefault();
        if (this.validator.allValid()) {
            CommonHelper.post('needQuote', this.state.quote, (status, response) => {
                if (response.status) {
                    this.setState({
                        quote: {
                            first_name: '',
                            last_name: '',
                            phone: '',
                            country: '',
                            email: '',
                        }
                    })
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    showModal(id) {
        window.$(id).modal({backdrop: 'static'});
    }

    render() {
        console.log("activePage", this.state.activePage);

        let products = this.state.products.map((product) => {
            return <div key={product.id} className="col-xl-3 col-lg-3 col-md-4 col-6">

                <div className="product-grid5">
                    <div className="product-image5">
                        <Link to={'product/' + product.id}>
                            <img className=""
                                 src={product.full}/>

                        </Link>
                    </div>
                    <div className="product-content">
                        <h3 className="title">
                            <Link to={'product/' + product.id}>{product.prod_name} </Link>
                        </h3>
                    </div>
                </div>

            </div>
        });

        return (
            <div className={'ecatlog-page'}>
                <div className="tectical-partneers">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-5">
                        <h3>E-Catalog</h3>
                        </div>
                        <div className="col-lg-7 col-md-7 bg-content-tectical text-end">
                                <div className="btn-group mt-0 justify-content-end">
                                    <button type="button" className="btn btn-tectical" data-bs-toggle="modal" data-bs-target="#learnabout">
                                        Learn About Our Services
                                        </button>               
                    
                                </div>
                        </div>

        {/* Modal */}

                    </div>
                    </div>
                </div>
                <section className="">
                    <div className="ps-new-arrivals">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className='left-asidebar'>
                                    <div className="filterleft">
                                        <div className="tital-cat">Search</div>
                                        <div>
                                            <input type={'search'} name={'search_product'} list={'searchresults'}
                                                   placeholder={'Search'}
                                                   className="form-control" value={this.state.searchKeyword}
                                                   onChange={this.searchProduct}
                                                   ref={(input) => {
                                                       this.searchInputField = input;
                                                   }}/>
                                        </div>
                                    </div>
                                    <div className="filterleft">
                                        <div className="tital-cat">Brand</div>
                                        <FilterComponent
                                            key={1}
                                            active={this.state.query}
                                            category={this.state.brand}
                                            queryString={this.filterFunc}
                                            type={'brand'}
                                            fieldName={'manufacturer_name'}
                                        />

                                        <div className="tital-cat">Category</div>
                                        <FilterComponent
                                            key={2}
                                            active={this.state.query}
                                            category={this.state.category}
                                            queryString={this.filterFunc}
                                            type={'cat'}
                                            fieldName={'sin'}
                                        />
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-9 productright">
                                    <div className="row">
                                        {products}
                                    </div>
                                    <div className={'row'}>
                                        {this.state.products.length > 0 && <Pagination
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.per_page}
                                            totalItemsCount={this.state.total_records}
                                            onChange={this.handlePageChange}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




     <div className="modal fade" id="learnabout" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">About Products And Services</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className="contantpop-up">
                <p>Aspetto offers over 20,000 products and services to meet our
                  customers multi-faceted needs. From government contractor
                  necessities to IT services for private companies, we provide our
                  customers with the goods and services needed to perform their jobs
                  and complete their missions in a safe and productive manner. </p>
                <p>
                  As a Small Business Administration (SBA) 8(a) certified company,
                  Aspetto has been approved by the federal government to qualify under
                  this classification and has been offered the opportunity to serve
                  the needs of various federal government agencies. With this
                  certification in place, federal agencies can easily select Aspetto
                  as their go-to product and service provider for their varied agency
                  needs.
                </p>
                <p>
                  Aspetto also possesses a General Services Administration (GSA)
                  Schedules Contract, meaning that our company has been hand-selected
                  by the United States federal government to provide commercially
                  available products and services to government agencies. When federal
                  agencies choose Aspetto for their product and service needs, agency
                  officials can rest easy knowing that Aspetto's items have already
                  been approved by the Administration.
                </p>
                <p>
                  As a Defense Logistics Agency (DLA) FedMall supplier, buyers of
                  Aspetto goods can proceed with their purchase with the knowledge
                  that the company has been approved by the Agency to service the
                  needs of the Department of Defense, federal, state, and authorized
                  local agencies.
                </p>
                <p>
                  Aspetto holds a Virginia State Body Armor contract and provides
                  state law enforcement agencies with direct purchase opportunities to
                  buy work-related gear directly from Aspetto. Since Aspetto is an
                  approved company, government officials can easily and confidently
                  purchase the protective items they need.
                </p>
                <p>
                  When it comes to the production process and our service offerings,
                  Aspetto partners with only the best companies in order to provide
                  high-quality products and services, which allow you to get the job
                  done safely and efficiently.
                </p>
                <p>
                  At Aspetto, our team covers all aspects of production. Starting from
                  the inception of an idea and goal to producing a prototype to
                  engaging in the mass production, we will handle all phases of
                  production. The Aspetto research and development lab is located
                  right here in Fredericksburg, Virginia, which means that management
                  is able to provide a hands-on approach to all projects along with
                  collaborating with a team of advisors on an as-needed basis.
                </p>
                <p>
                  The Aspetto production lab is set up with all of the necessary
                  equipment needed to produce the products for you and get them to you
                  quickly. Laser cutters, Gerber automatic cutters, and automated
                  stitch machines are used to enable the production process to be
                  highly efficient and on point every time. Our manufacturing
                  facilities utilized in production are ISO 9001:2015, which means
                  that you are getting high-quality, properly-produced products. In
                  addition, Aspetto’s production capacity is such that it can be
                  expanded based on the requirement of your agency or company.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>




            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cartReducer,
});
const mapDispatchToProps = dispatch => ({
    addToCartAction: (payload, type, cb) => dispatch(addToCart(payload, type, cb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GetquotePage))
