import React, {Component} from 'react';
import './tactical.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import NavLink from "../_layout/template-1/nav-link";
import { Link } from 'react-router-dom';
class TacticalPage extends Component {
constructor(props) {
super(props);

}

componentDidMount(){

  new window.Swiper('.flex-partners', {
     slidesPerView: 5,
     autoplay: {
         delay: 1000,
     },
     breakpoints: {
      0: {
          slidesPerView: 3,
      },
      478: {
          slidesPerView: 3,
      },
      576: {
          slidesPerView: 4,
      },
      768: {
          slidesPerView: 5,
      },
      1024: {
          slidesPerView:5,
      },
      1200: {
          slidesPerView:5,
      },
      1300: {
        slidesPerView: 5,
    },
  },
  });

}


render() {



return (
<div className={'about-us-page  abouthding'}>
<div className="wrapper-content">
<div className="video-slider">
          <div className="container">
            <div className="swiper-technical">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
				
				 
                  <video preload="metadata" width="100%" height="100%" id="myVideo" autoPlay={true} loop={true} playsInline={true}>
                    <source src="images/home-page.mp4" type="video/mp4" />
                  </video>
				  
				  
				   <div className="hero-line">
                    <h3>What you need <span>when you need it most</span></h3>
                  </div>
                </div>
              </div>
              {/*<div className="swiper-button-next" />
              <div className="swiper-button-prev" />*/}
            </div>
          </div>
        </div>
          <div className="container">
            <div className="tectical-about mt-3">
              <div className="tetical-headlines">
                <h6>↳ Makers of America’s First Bullet-Resistant Clothing Line</h6>
              </div>
              <div className="tetical-headlines">
                <h6 type="button" className="active" data-bs-toggle="modal" data-bs-target="#kwidclip">
                ↳ Makers of KWIQ Clip
              </h6>
              </div>
              <div className="tetical-headlines">
                <h6>↳ Makers of the MACH-V DoD’s first female focused body armor</h6>
              </div>
              <div className="pera-content">
                <p className="text-center">We manufacture and distribute cutting-edge, high performance tactical equipment for the U.S. military and both domestic and international law enforcement communities. </p>
                <p className="text-center">Through our focus on research and development, coupled with unique systems integration capabilities and innovative technology, we have developed patented solutions for some of the highest performing products available in the industry.</p>
                <p>We have successfully delivered thousands of products to the Department of Defense, Department of State, and various other federal and state law enforcement agencies. Customer service is in our DNA - you will feel the love. </p>
                <div className="bg-content-tectical">
                  <div className="btn-group">
                      <a href={'pdf/Aspetto-Catalog.pdf'} className="btn btn-tectical" target="_blank">
                      Download PDF Catalog 
                          <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path d="M478,256,302,432l-21.21-21.2L420.6,271H34V241H420.6L280.75,101.16,302,80Z" />
                          </svg>
                      </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tectical-partneers">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <h3>Our Clients</h3>
                <p>Make it simple, use our contract vehicles. We’re SBA 8(a) Certified</p>
                {/*<img src="images/army-man.png">*/}
              </div>
              <div className="col-lg-6 col-md-12">
              <div className="swiper flex-partners">
               <div className="swiper-wrapper">
                   <div className="swiper-slide item"><img src="./images/our-client/01.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/02.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/03.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/04.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/05.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/06.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/07.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/08.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/09.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/10.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/11.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/12.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/13.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/14.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/15.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/16.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/17.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/18.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/19.png" alt="partners" /></div>
                  <div className="swiper-slide item"><img src="./images/our-client/20.png" alt="partners" /></div>
               </div>
            </div>
 
              </div>
            </div>
          </div>
        </div>
        <div className="services-areas">
          <div className="container">
            <div className="head-services">
              <h2>Allow us to help <span>you find the right products</span></h2>
              <Link to={'/e-Catalog'} className="btn btn-explore">view E Catalog</Link>
            </div>
            <div className="row">
              <div className="col">
                <div className="items">
                  <img src="images/partners-logo/jet.svg" alt="partners" />
                  <span>Aerospace</span>
                </div>
              </div>
              <div className="col">
                <div className="items">
                  <img src="images/partners-logo/helmet.svg" alt="partners" />
                  <span>Clothing &amp; Equipment</span>
                </div>
              </div>
              <div className="col">
                <div className="items">
                  <img src="images/partners-logo/satellite.svg" alt="partners" />
                  <span>C5ISR</span>
                </div>
              </div>
              <div className="col">
                <div className="items">
                  <img src="images/partners-logo/protective-equipment-svgrepo-com.svg" alt="partners" />
                  <span>Protective Equipment</span>
                </div>
              </div>
              <div className="col">
                <div className="items">
                  <img src="images/partners-logo/shelter.svg" alt="partners" />
                  <span>Office Supplies</span>
                </div>
              </div>
              <div className="col">
                <div className="items">
                  <img src="images/partners-logo/cross.svg" alt="partners" />
                  <span>Medical Supplies &amp; Training</span>
                </div>
              </div>
              <div className="col">
                <div className="items">
                  <img src="images/partners-logo/logistics-truck-svgrepo-com.svg" alt="partners" />
                  <span>Logistics</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tectical-partneers brand-lists">
          <div className="container">
            <div className="head-services">
              <div>
              <h2>Request a Quote Now!</h2>
              <div className="quote-text-btn">
                 <Link to={'/getquote'} className="btn d-inline-block">Get Started      <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M478,256,302,432l-21.21-21.2L420.6,271H34V241H420.6L280.75,101.16,302,80Z" />
                  </svg></Link>
              </div>
              </div>
              <h6>We have over 40,000 products </h6>

            </div>
          </div>
        </div>

        {/* Modal */}
        <div className="modal fade" id="kwidclip" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Makers of KWIQ Clip</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-6 text-center">
                      <div className="pdfimage"><img className="w-100" src="images/pd-fimage.png" alt="Logo" /></div>
                  </div>
                  <div className="col-sm-6 carrer_frm_container popupbg">
                      <div className="popupul">
                        <ul>
                            <li> 300lbs Tensile Strength</li>
                            <li>Self Cleaning</li>
                            <li>Injection molded with high strength and self lubricating acetal</li>
                            <li>*Data sheets available upon request - please contact for order information.</li>
                            <li>Colors: Coyote, Black, Ranger Green</li>
                        </ul>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

</div>





);
}
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TacticalPage))
