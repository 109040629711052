import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

class NavLink extends React.Component {
    render() {
        var isActive = this.context.router.route.location.pathname === this.props.to;
        var className = isActive ? 'active' : '';
        return (
            <li  className={(this.props.className)?this.props.className:className}>
                <Link  {...this.props}>
                    {this.props.children}
                </Link>
            </li>
        );
    }
}

NavLink.contextTypes = {
    router: PropTypes.object.isRequired
};

export default NavLink;