import React, {Component} from 'react';
import './news-details.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import CommonHelper from "../../helper/CommonHelper";
import PartnerEventComponent from "../../components/parntner-event.component";

class NewsDetailsPage extends Component {
    static contextTypes = {
        router: () => true, // replace with PropTypes.object if you use them
    }
    constructor(props) {
        super(props);
        this.state = {
            news: {},
            recent_posts: []
        }
    }

    componentDidMount() {
        this.getNews();
    }

    getNews() {
        const {slug} = this.props.match.params;
        CommonHelper.get('getSingleNews/' + slug, (status, response) => {
            this.setState({news: response.data})
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.getNews();
        }
    }

    render() {

        return (
            <div>
                {/*<Breadcrumb activePageName={this.state.news.title}/>*/}


                <div className="blogss-cart">
                    <div className="container">
                        <div className="row">
                            <div className=" col-md-12 back mb-3">
                                <button onClick={this.context.router.history.goBack} className="btn">
                                    <i className="fa fa-arrow-left" aria-hidden="true"/>Back
                                </button>
                            </div>
                            <div className="col-md-12">
                                <div className="blogsleft Knock">
                                    {(this.state.news.full) &&
                                    <div className={'blog-single-img'}>

                                        <div className="blog-img">
                                            <img src={this.state.news.full}/>
                                        </div>
                                    </div>}
                                    <div className="blog_right_content post-content">
                                        <h2 className='mt-2'>{this.state.news.title}</h2>
                                        <ul className="meta">
                                            <li>{this.state.news.createdPost}</li>
                                        </ul>
                                        <div dangerouslySetInnerHTML={{__html: this.state.news.content}}></div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className={'col-md-3'}>
                                <PartnerEventComponent/>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsDetailsPage))