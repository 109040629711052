import React, {Component} from 'react';
import './login.page.scss'
import {Link, withRouter} from "react-router-dom";
import {Breadcrumb} from "../../components/breadcrumb.component";
import connect from "react-redux/es/connect/connect";
import {loginAction} from "./login.action";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
        this.handleChanges = this.handleChanges.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChanges(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.loginAction(this.state, (data) => {
            this.props.history.push('/');
        })
    }

    render() {
        return (
            <div>
                <Breadcrumb activePageName={'Login'}/>
                <div className="ps-page">
                    <div className="ps-account">
                        <div className="container">
                            <form className="ps-form--account" onSubmit={this.handleSubmit}>
                                <div className="ps-form__header">
                                    <h3>Login</h3>
                                </div>
                                <div className="ps-form__content">
                                    <div className="form-group">
                                        <label>Email address<sup>*</sup></label>
                                        <input className="form-control" name={'email'}
                                               onChange={this.handleChanges} type="text"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input className="form-control" name={'password'}
                                               onChange={this.handleChanges} type="password"/>
                                    </div>
                                    <div className="form-group submit">
                                        <button className="ps-btn ps-btn--outline ps-btn--black">Submit</button>
                                        <div className="ps-checkbox">
                                            <input className="form-control" type="checkbox" id="remember"
                                                   name="remember"/>
                                            <label htmlFor="remember">Remember</label>
                                        </div>
                                        <p><a href="forgot-password.html">Lost your password?</a><br/><strong> or
                                            <Link to={'/register'}>
                                                Create your account
                                            </Link></strong></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = (state) => ({
    user: state.loginReducer,
});
const mapDispatchToProps = dispatch => ({
    loginAction: (payload, cb) => dispatch(loginAction(payload, cb))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));