import React, {Component} from 'react';
import './cart.scss'
import {cartList} from "./cart.action";
import {Breadcrumb} from "../../components/breadcrumb.component";
import CommonHelper from "../../helper/CommonHelper";
import {addToCart, removeCart} from "../home/home.action";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import Config from "../../helper/Config";

class CartPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartList: [],
            counter: {},
            products: {},
            selectedSize: {}
        };
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.handleChanges = this.handleChanges.bind(this);
        this.checkoutPage = this.checkoutPage.bind(this);
    }

    componentDidMount() {
        this.props.cartList((list) => {
            let products = Object.keys(list);
            CommonHelper.post('cart', {products: products}, (status, response) => {
                this.setState({cartList: response.data, products});
            });
        });
    }

    handleChanges(event, item) {
        item.size = event.target.value;
        this.setState({selectedSize: {[item.id]: event.target.value}})
    }

    increment(item) {
        if (this.state.selectedSize[item.id])
            item.selectedSize = this.state.selectedSize[item.id];
        else
            item.selectedSize = this.props.cart.cart[item.id].size;

        this.props.addToCartAction(item, 'add', (data) => {

        });
    }

    decrement(item) {
        if (this.state.selectedSize[item.id])
            item.selectedSize = this.state.selectedSize[item.id];
        else
            item.selectedSize = this.props.cart.cart[item.id].size;

        this.props.addToCartAction(item, 'remove', (data) => {

        });
    }

    removeCartItem(item) {
        this.props.removeCart(item)
    }

    checkoutPage() {
        this.props.history.push('/checkout');
    }


    render() {


        const productList = this.state.cartList.map((product) => {


            let products = '';
            if (this.props.cart.cart[product.id]) {

                let sizeList = '';
                if (product.size) {
                    let sizeArr = product.size.split(',');
                    sizeList = sizeArr.map((size, index) => {
                        return (<option key={index} value={size}>{size}</option>);
                    });
                }
                products = (<div key={product.id} className="shoppingcrtleft">
                    <div className="shoppingcrtmg">
                        <a href="#">
                            <img src={product.product_images_single.medium1}/>
                        </a>
                    </div>
                    <div className="cartrr">
                        <a href="#">{product.name}</a>
                        <p>Size:
                        </p>
                        <div className="ps-block__action">
                            <div className="input-group-prepend">

                                <form id="myform" method="POST" action="#">
                                    <select name={'selectedSize'} value={this.props.cart.cart[product.id].size}
                                            onChange={(event) => this.handleChanges(event, this.props.cart.cart[product.id])}>
                                        <option value={''}>Select Size</option>
                                        {sizeList}
                                    </select>
                                    <div className={'inc-dec-input'}>
                                        <input onClick={() => this.decrement(product)} type="button" defaultValue="-"
                                               className="qtyminus"/>
                                        <div
                                            className={'cart-qty-count'}> {(this.props.cart.cart[product.id]) && this.props.cart.cart[product.id].qty}</div>
                                        <input onClick={() => this.increment(product)} type="button" defaultValue="+"
                                               className="qtyplus"/>
                                    </div>

                                </form>
                                <p>{Config.conf.currencySymbol}{(this.props.cart.cart[product.id]) && this.props.cart.cart[product.id].totalAmt}</p>
                            </div>
                        </div>
                        <div className="closw" onClick={() => this.removeCartItem(product)}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </div>
                    </div>
                </div>);
            }

            return products;

        });
        return (
            <div>
                <Breadcrumb activePageName={'Shopping Cart'}/>
                <div className="ps-shopping-cart">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <h4>Shopping Cart</h4>
                                <div>
                                    {(productList == '') ? 'Cart is empty' : productList}
                                </div>
                                <Link className="ps-btn ps-btn--outline ps-btn--black ps-btn--fullwidth"
                                      to={'/product-filter'}>
                                    Continue Shopping
                                </Link>
                            </div>
                            <div className="col-md-4">
                                <div className="shoppingcrtright">
                                    <div className="ps-form--cart-process">
                                        {/*   <figure>
                                            <figcaption>Enter Coupon</figcaption>
                                            <div className="form-group">
                                                <input className="form-control" type="text" placeholder="Coupon"/>
                                            </div>
                                            <div className="form-group">
                                                <button className="ps-btn">Apply Coupon</button>
                                            </div>
                                        </figure>
                                        <figure>
                                            <figcaption>Caculate Shipping</figcaption>
                                            <div className="form-group">
                                                <select className="form-control">
                                                    <option>USA</option>
                                                    <option>viet Name</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" type="text" placeholder="postcode/ZIP"/>
                                            </div>
                                            <div className="form-group">
                                                <button className="ps-btn">Update Total</button>
                                            </div>
                                        </figure>
                                        <div className="ps-block__total">
                                            <h4>SubTotal <span> $79.00</span></h4>
                                            <p>Shipping</p>
                                            <div className="ps-radio">
                                                <input className="form-control" type="radio" id="shipping-1"
                                                       name="shipping"/>
                                                <label htmlFor="shipping-1">Standard: <span>$10.00</span></label>
                                            </div>
                                            <div className="ps-radio">
                                                <input className="form-control" type="radio" id="shipping-2"
                                                       name="shipping"/>
                                                <label htmlFor="shipping-2">Express: <span>$10.00</span></label>
                                            </div>
                                            <h5>Total: <span>$89.00</span></h5>
                                        </div>*/}
                                        <div className="submit">
                                            <button onClick={this.checkoutPage}
                                                    className="ps-btn ps-btn--lg ps-btn--fullwidth ps-btn--curve">
                                                Process To Checkout
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cartReducer
});
const mapDispatchToProps = dispatch => ({
    cartList: (cb) => dispatch(cartList(cb)),
    addToCartAction: (payload, type, cb) => dispatch(addToCart(payload, type, cb)),
    removeCart: (payload) => dispatch(removeCart(payload)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartPage))