import React, {Component} from 'react';
import './contact-us.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import CommonHelper from "../../helper/CommonHelper";



class ContactUsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactUs: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                message: ''
            }
        };
        this.contactUsSubmit = this.contactUsSubmit.bind(this);
        this.handleChanges = this.handleChanges.bind(this);
        this.validator = new SimpleReactValidator();
    }

    contactUsSubmit(event) {
        event.preventDefault();
        if (this.validator.allValid()) {
            CommonHelper.post('contact-us', this.state.contactUs, (status, response) => {
                if (response.status) {
                    this.setState({
                        contactUs: {
                            first_name: '',
                            last_name: '',
                            phone: '',
                            country: '',
                            email: '',
                        }
                    })
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            console.log("error called")
        }
    }

    handleChanges(event) {
        this.setState({contactUs: {...this.state.contactUs, [event.target.name]: event.target.value}})
    }

    render() {

        return (
            <div className="contact-us-page">
            <div className="tectical-partneers">
                <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                    <h3>Contact Us</h3>
                    <p> Aspetto team is here to serve you Monday to Friday 8am to 5pm EST. If you have any questions about our products or services, please feel free to reach out to us.</p>
                    {/*<img src="images/army-man.png">*/}
                    </div>
                </div>
                </div>
            </div>
                <section >
                    <div className="container">
                                            
                    <div className="contact-box row">
                                            <div className="col-md-6">
                                            <div className='contact-margin'>
                                            <h6><i className="fas fa-map-marker-alt"></i> Address</h6>
                                                <h5> Corporate Headquarters</h5>
                                                <p>  1691 Emancipation Hwy.<br/>
                                                Fredericksburg,  VA 22401
                                                USA 
                                               </p>
                                            </div>
                         
 
                                            </div>
                                            <div className="col-md-6">
                                                <div className='contact-margin'>
                                                <h6><i className="fas fa-phone-alt"></i> Lets Talk</h6>
                                                <h5>Phone:  <a title="contact feel free with us"
                                                href="tel:5405478487">540-547-8487 </a></h5>
                                                <h5>Fax: <span>540-300-2669</span></h5>
                                                <h5>Email: 
                                                 <a title="contact feel free with us"
                                                href="mailto:info@aspettoinc.com">info@aspettoinc.com</a></h5>
                                                </div>
              
                                            </div>
                                </div>
                        <div className="row justify-content-center">
                                <div className="col-md-12">
                                <form onSubmit={this.contactUsSubmit} className="contact-form-box">
                                    <div className="row">
                                        <label className="mt-0" htmlFor="first-name">Tell us your name*</label>
                                        <div className="col-md-6 col-6"
                                             data-validate="Type first name">
                                            <input
                                                id="first-name"
                                                className="form-control"
                                                type="text"
                                                name="first_name"
                                                value={this.state.contactUs.first_name || ''}
                                                onChange={this.handleChanges}
                                                placeholder="First name"/>
                                            <span className="focus-input100"/>

                                            {this.validator.message('first_name', this.state.contactUs.first_name, 'required')}
                                        </div>
                                        <div className="col-md-6 col-6"
                                             data-validate="Type last name">
                                            <input className="form-control" type="text"
                                                   name="last_name"
                                                   value={this.state.contactUs.last_name || ''}
                                                   onChange={this.handleChanges}
                                                   placeholder="Last name"/>
                                            <span className="focus-input100"/>
                                            {this.validator.message('last_name', this.state.contactUs.last_name, 'required')}
                                        </div>
                                        <label className="" htmlFor="email">Enter your email *</label>
                                        <div className="wrap-input100 validate-input"
                                             data-validate="Valid email is required: ex@abc.xyz">
                                            <input id="email" className="form-control" type="text"
                                                   name="email"
                                                   value={this.state.contactUs.email || ''}
                                                   onChange={this.handleChanges}
                                                   placeholder="Eg. example@email.com"/>
                                            <span className="focus-input100"/>
                                            {this.validator.message('email', this.state.contactUs.email, 'required|email')}
                                        </div>
                                        <label className="" htmlFor="phone">Enter phone number</label>
                                        <div className="wrap-input100">
                                            <input id="phone" className="form-control" type="text" name="phone"
                                                   value={this.state.contactUs.phone || ''}
                                                   onChange={this.handleChanges}
                                                   placeholder=" +1 800 00000000"/>
                                            <span className="focus-input100"/>
                                            {this.validator.message('phone', this.state.contactUs.phone, 'required')}
                                        </div>
                                        <label className="" htmlFor="message">Message *</label>
                                        <div className="wrap-input100 validate-input"
                                             data-validate="Message is required">
                                        <textarea id="message" className="form-control" name="message"
                                                  value={this.state.contactUs.message || ''}
                                                  onChange={this.handleChanges}
                                                  placeholder="Write us a message"/>
                                            <span className="focus-input100"/>

                                            {this.validator.message('message', this.state.contactUs.message, 'required')}
                                        </div>
                                        <div className="col-md-12 text-center mt-3">
                                            <button type="submit" className="btn">
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactUsPage))
