import React, {Component} from 'react';
import './getquote.scss'
import connect from "react-redux/es/connect/connect";
import CommonHelper from "../../helper/CommonHelper";
import {addToCart} from "../home/home.action";
import {Link, withRouter} from "react-router-dom";
import Pagination from "react-js-pagination";
import SimpleReactValidator from "simple-react-validator";
import QuotePopup from "../../components/quote-popup";


const FilterComponent = ({category, queryString, active, type, fieldName}) => {
    const categoryList = category.map((cat) => {
        return (
            <li key={cat.id} className={active[type] == cat[fieldName] ? 'active' : ''}>
                
                <a 
                   onClick={() => queryString(type, cat[fieldName], null)}>
                    {(fieldName == 'sin') ? cat['sinName'] : cat[fieldName]}
                </a>
            </li>)
    });

};

class ProductFilterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: [],
            brand: [],
            category: [],
            products: [],
            product_max_amt: 0,
            query: {
                brand: '',
                cat: '',
                activePage: 1
            },
            priceFilter: 1,
            searchKeyword: '',
            per_page: 0,
            total_records: 0,
            activePage: 1,
            quote: {
                product_id: '',
                first_name: '',
                last_name: '',
                phone: '',
                country: '',
                email: '',
            },
        };
        this.getBrands = this.getBrands.bind(this);
        this.filterFunc = this.filterFunc.bind(this);
        this.filterProduct = this.filterProduct.bind(this);
        this.searchProduct = this.searchProduct.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.quoteSubmit = this.quoteSubmit.bind(this);
        this.searchInputField = React.createRef();
        this.searchTimeOut = null;
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.filterProduct();
        CommonHelper.get('getCountry', (status, res) => {
            this.setState({country: res.data.countries})
        })
    }

    filterProduct() {
        const params = new URLSearchParams(this.props.location.search);

        const getPageNum = localStorage.getItem('filterPage');
        let page = (params.get('page')) ? params.get('page') : 1;

        if (getPageNum)
            page = getPageNum;

        let queryStr = '?page=' + page, activeCat = '';

        if (this.props.location.search) {
            activeCat = '?category=' + params.get('category') + '&brand=' + params.get('brand') + '&search=' + params.get('search') + '&page=' + page;
            queryStr = activeCat;
        }
        this.getBrands(queryStr);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.filterProduct();
        }
    }

    getBrands(queryString = '') {
        const params = new URLSearchParams(this.props.location.search);
        const getPageNum = localStorage.getItem('filterPage');
        let page = (params.get('page')) ? params.get('page') : 1;
        if (getPageNum)
            page = getPageNum;

        CommonHelper.get('getDefenceBrand' + queryString, (status, res) => {
            this.setState({
                brand: res.data.brand,
                category: res.data.category,
                products: res.data.products.data,
                total_records: res.data.products.total,
                per_page: res.data.products.per_page,
                activePage: parseInt(page),
                query: {brand: params.get('brand'), cat: params.get('category'), activePage: params.get('page')}
            });
        })
    }

    filterFunc(type, val, pag) {
        let queryS = '?category=&brand=&search=&page=';
        let category = this.state.query.cat;
        if (category == null) {
            category = '';
        }
        let brand = this.state.query.brand;
        if (brand == null) {
            brand = ''
        }

        let page = 1;
        localStorage.setItem('filterPage', page);


        if (type == 'brand') {
            queryS = '?category=' + category + '&brand=' + val + '&search=' + this.state.searchKeyword + '&page=' + page;
        } else if (type == 'cat') {
            queryS = '?category=' + val + '&brand=' + brand + '&search=' + this.state.searchKeyword + '&page=' + page;
        } else if (type == 'page') {
            queryS = '?category=' + category + '&brand=' + brand + '&search=' + this.state.searchKeyword + '&page=' + val;
        }

        this.props.history.push('/e-catalog' + queryS)
    }

    searchProduct(event) {
        let search = event.target.value;
        let searchField = event.target;
        this.setState({searchKeyword: search});
        clearTimeout(this.searchTimeOut);

        let category = this.state.query.cat;
        if (category == null) {
            category = '';
        }
        let brand = this.state.query.brand;
        if (brand == null) {
            brand = ''
        }

        this.searchTimeOut = setTimeout(() => {
            this.props.history.push('/e-Catalog?brand=' + brand + '&category=' + category + '&search=' + search);
            searchField.focus();
        }, 1500);
    }

    handlePageChange(pageNumber) {

        let category = this.state.query.cat;
        if (category == null) {
            category = '';
        }
        let brand = this.state.query.brand;
        if (brand == null) {
            brand = ''
        }
        let queryS = '?category=' + category + '&brand=' + brand + '&search=' + this.state.searchKeyword + '&page=' + pageNumber;

        CommonHelper.get('getDefenceBrand' + queryS, (status, res) => {
            localStorage.setItem('filterPage', pageNumber);
            this.setState({
                activePage: pageNumber,
                products: res.data.products.data,
                total_records: res.data.products.total,
                per_page: res.data.products.per_page,
            });
        });
        // this.filterFunc('page', pageNumber)
    }

    quoteSubmit(event) {
        event.preventDefault();
        if (this.validator.allValid()) {
            CommonHelper.post('needQuote', this.state.quote, (status, response) => {
                if (response.status) {
                    this.setState({
                        quote: {
                            first_name: '',
                            last_name: '',
                            phone: '',
                            country: '',
                            email: '',
                        }
                    })
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    showModal(id) {
        window.$(id).modal({backdrop: 'static'});
    }

    render() {


        return (
          <div className='contact-us-page'>
            <div className='tectical-partneers'>
            <div className={'container'}>
            <QuotePopup country={this.state.country}/>

              </div>
            </div>
   
               
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cartReducer,
});
const mapDispatchToProps = dispatch => ({
    addToCartAction: (payload, type, cb) => dispatch(addToCart(payload, type, cb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductFilterPage))
