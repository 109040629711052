export default (state = {}, action) => {
    switch (action.type) {
        case 'LOGIN_REQUEST':
            return {
                result: action.payload
            };
        case 'USER':
            return {user: action.payload};
        case 'LOGOUT':
            console.log("logout")
            return {user: {}};
        default:
            return state
    }
}