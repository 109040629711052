import React, {Component} from 'react';
import './blog-details.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import CommonHelper from "../../helper/CommonHelper";

class BlogDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: {},
            recent_posts: []
        }
    }

    componentDidMount() {
        this.getBlog();
    }

    getBlog() {
        const {slug} = this.props.match.params;
        CommonHelper.get('getSingleBlog/' + slug, (status, response) => {
            this.setState({blog: response.data.blog, recent_posts: response.data.recent_posts})
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.getBlog();
        }
    }

    render() {

        return (
            <div>
                <Breadcrumb activePageName={this.state.blog.title}/>

				
                <div className="blogss-cart">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="blogsleft Knock">
                                    {(this.state.blog.blog_images && this.state.blog.blog_images.length > 1) &&
                                    <div className="carousesilder">
                                        <div id="carouselExampleSlidesOnly" className="carousel slide"
                                             data-ride="carousel">
                                            <div className="carousel-inner">
                                                {this.state.blog.blog_images.map((img, i) => {
                                                    return (
                                                        <div key={img.id}
                                                             className={(i == 0) ? 'carousel-item active' : 'carousel-item'}>
                                                            <img src={img.full}/>
                                                        </div>)
                                                })}
                                            </div>

                                            <a className="carousel-control-prev" href="#carouselExampleSlidesOnly"
                                               role="button" data-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                            <a className="carousel-control-next" href="#carouselExampleSlidesOnly"
                                               role="button" data-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </div>
                                    </div>}
                                    {(this.state.blog.blog_images && this.state.blog.blog_images.length == 1) &&
                                    <div className={'blog-single-img'}>
                                        {this.state.blog.blog_images.map((img) => {
                                            return (
                                                <div key={img.id} className="blog-img">
                                                    <img src={img.full}/>
                                                </div>)
                                        })}
                                    </div>}
                                    <div className="blog_right_content post-content">
                                        <h2>{this.state.blog.title}</h2>
                                        <ul className="meta">
                                            <li>Posted By
                                                : {this.state.blog.user && this.state.blog.user.first_name}</li>
                                            <li>{this.state.blog.postDate}</li>
                                            <li>{this.state.blog.blog_comments_count}&nbsp;&nbsp;Comments</li>
                                        </ul>
                                        <div dangerouslySetInnerHTML={{__html: this.state.blog.content}}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="blogright">
                                    <div className="simple-banner">
                                        <a href="#"> <img src="./image/blogright1.jpg"/> </a>
                                        <a href="#"> <img src="./image/blogright2.jpg"/> </a>
                                    </div>
                                    <div className="widget">
                                        <h4>Recent Posts</h4>
                                        <ul>
                                            {this.state.recent_posts &&
                                            this.state.recent_posts.map((recent) => {
                                                return (<li>
                                                    <div className="media">
                                                        <Link to={'/blog/' + recent.slug}
                                                              className="thumb-holder pull-left">
                                                            <img src={recent.blog_single_images.medium}/>
                                                        </Link>
                                                        <div className="media-body">
                                                            <Link to={'/blog/' + recent.slug}>{recent.title}</Link>
                                                            <div className="posted-date">{recent.postDate}</div>
                                                        </div>
                                                    </div>
                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlogDetailsPage))