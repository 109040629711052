import React from 'react';
import Helmet from 'react-helmet';
import Config from '../helper/Config'

const TitleComponent = ({title}) => {
    const defaultTitle = Config.conf.app_name;
    return (
        <Helmet>
            <title>{title ? title : defaultTitle}</title>
        </Helmet>
    );
};

export {TitleComponent};