import React, {Component} from 'react';
import './news-feed.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import CommonHelper from "../../helper/CommonHelper";
import Pagination from "react-js-pagination";

class NewsFeedPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            activePage: 1,
            per_page: 0,
            total_records: 0,
        };

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        const getPageNum = localStorage.getItem('newsPage');
        this.getNews(getPageNum);
    }

    getNews(pageNumber) {
        let page = (pageNumber) ? pageNumber : 1;
        CommonHelper.get('getNews?page=' + page, (status, res) => {
            this.setState({
                news: res.data.data,
                per_page: res.data.per_page,
                total_records: res.data.total,
                activePage: parseInt(pageNumber)
            });
        })
    }

    handlePageChange(pageNumber) {
        localStorage.setItem('newsPage', pageNumber);
        this.getNews(pageNumber)
    }

    render() {

        return (


            <div>

                <section className="newsroom-post mt-5">
                    <div className="container">
                        <div className="row">
                            {this.state.news.map((news) => {
                                return <div key={news.id} className="col-lg-4 col-md-4">
                                        <div className="item-blog">
                                            <div className="thumb-blogs">
                                                <Link to={'/news-details/' + news.id}>
                                                <img className="" src={news.thumb}/>
                                                </Link>
                                            </div>


                                            <div className="content-blogs">
                                                <span className="blog-date-time">{news.createdPost}</span>
                                                <h6 className="card-title"><Link
                                                    to={'/news-details/' + news.id}>{news.title}</Link></h6>
                        
                                                <p className=""
                                                    dangerouslySetInnerHTML={{__html: news.shortContent}}/>
                                            </div>
                                            {/* <div className="card-footer">
                                                <hr/>
                                                <ul className="author-2">
                                                    <li><a href="#"><img className src="./images/12s.jpg" alt="..."/> &nbsp;
                                                        <span> Sean</span></a></li>
                                                </ul>
                                                /.bottom_data
                                                <ul className="bottom_data pull-right ">
                                                    <li><i className="fa fa-comment-o"/>200</li>
                                                    <li><i className="fa fa-heart-o"/>30</li>
                                                    <li><i className="fa fa-align-left"/>7 Min Read</li>
                                                </ul>
                                                /.bottom_data
                                            </div>*/}
                                        </div>

                                </div>
                            })}
                        </div>
                        <div className={'row'}>
                            <Pagination
                                prevPageText='prev'
                                nextPageText='next'
                                firstPageText='first'
                                lastPageText='last'
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.per_page}
                                totalItemsCount={this.state.total_records}
                                onChange={this.handlePageChange}
                            />
                        </div>
                    </div>
                </section>
            </div>


        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsFeedPage))