import React from 'react'
import {Link} from "react-router-dom";
import {TitleComponent} from "./title.component";

export const Breadcrumb = ({activePageName}) => {
    return (
        <div>
            <TitleComponent title={activePageName}/>
            <div id="owl-demo" className="owl-carousel owl-theme topslider">
                <div className="item">
                    <img src="images/slide-2.jpg" alt="The Last of us"/>

                </div>
            </div>
            <div className="ps-breadcrumb">
                <div className="container">
                    <ol className="breadcrumb">
                        <li><Link to={'/'}>Home </Link></li>
                        <li className="active">&nbsp;{activePageName}</li>
                    </ol>
                </div>
            </div>
        </div>
    );
}