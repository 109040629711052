import React, {Component} from 'react';
import './capabilities.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import CommonHelper from "../../helper/CommonHelper";
import SimpleReactValidator from "simple-react-validator";
import PartnerEventComponent from "../../components/parntner-event.component";

class CapabilitiesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            past_performance: {},
            partner: {
                title: '',
                company_name: '',
                company_website: '',
                full_name: '',
                email: '',
                phone: '',
                type: 'supplier'
            }
        };
        this.showPastPerformance = this.showPastPerformance.bind(this);

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {

        window.$('.f-w').click(function () {
            if (window.$('.f-w').text() == "Read More...") {
                window.$(this).text("Read More")
            } else {
                window.$(this).text("Read less")
            }
        });
        const params = new URLSearchParams(this.props.location.search);
        if (params.get('post')) {
            window.$('html, body').animate({
                scrollTop: window.$("#" + params.get('post')).offset().top - 150
            }, 2000);
        }
    }



    showPastPerformance() {
        CommonHelper.get('singlePastPerformance', (status, res) => {
            this.setState({past_performance: res.data});
            window.$('#past-performance').modal('show');
        })
    }



    render() {

        return (
            <div className="abouthding">
                <div id="" className="owl-carousel owl-theme topslider">
                    <div className="item">
                        <div className="overlay"/>
                        <img src="./images/Capabilities.jpg" alt="The Last of us"/>
                    </div>
                    <div className="important_buttons margin20">
                        <a href="javascript:void(0);" onClick={this.showPastPerformance}>Past
                            Performance</a>
                    </div>
                </div>
                <section className="bggray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="" data-wow-duration="1.3s">
                                    <span>    Capabilities</span>
                                </h2>
                                <p className="" data-wow-duration="1.6s"> Aspetto offers defense-related services to
                                    help military, law enforcement, and private companies achieve their respective
                                    security goals. From protective vehicles to cybersecurity services and more, the
                                    Aspetto team will help you to complete your mission with safety and efficiency in
                                    mind.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-9 ulli ">
                                <div className="row py-4" id="protective-solutions">
                                    <div className="col-lg-3  push-lg-9 wow fadeInRight" data-wow-duration="1.3s">
                                        <img className="w-100 mb-3" src="./images/Protective.jpg"/>
                                    </div>
                                    <div className="col-lg-9  pull-lg-3 align-self-center wow fadeInLeft"
                                         data-wow-duration="1.3s">
                                        <h2 className="wow fadeInUp font-sm"
                                            data-wow-duration="1.3s">Protective <span>solution</span></h2>
                                        <p>Aspetto manufactures and distributes cutting-edge, high performance
                                            protective ballistic solutions for the U.S. Military and both domestic and
                                            international law enforcement communities. Through our focus on research and
                                            development, coupled with unique systems integration capabilities and
                                            innovative technology, Aspetto Inc. has developed patented solutions for
                                            some of the highest performing ballistic armor carrier systems
                                            available.
                                        </p>
                                        <div className="hidediv collapse mb-3" id="demo">
                                            <p>Aspetto Inc. has successfully delivered thousands of armored products to
                                                the Department of Defense, Department of State, and various other
                                                federal and state law enforcement agencies. Our goal is to continue to
                                                develop new applications and innovations for ballistic systems, by
                                                incorporating every latest advancement available in ballistic materials
                                                and technology. In addition, Aspetto Inc. continues to demonstrate an
                                                unwavering commitment to the highest customer service standards
                                                throughout our industry.
                                            </p>
                                            <a className="btn btn-danger " target="blank"
                                               href="./images/CAPABILITY_STATEMENT.pdf">View Full Capability
                                                Statement</a>
												   <a className="btn btn-danger ml-2 " target="blank"
                                               href="./images/E-Catalog.pdf">E Catalog</a>
                                        </div>
                                        <a className="f-w" data-toggle="collapse" data-target="#demo">Read More...</a>
                                    </div>
                                </div>
                               {/* <div className="row py-4 " id={'military-aviation-protection'}>
                                    <div className="col-lg-3    wow fadeInLeft" data-wow-duration="1.3s">
                                        <img className="w-100 mb-3" src="./images/Military-Aviation.jpg" alt=" "/>
                                    </div>
                                    <div className="col-lg-9    align-self-center  wow fadeInRight"
                                         data-wow-duration="1.3s">
                                        <h2 className="wow fadeInUp font-sm" data-wow-duration="1.3s">Military
                                            Aviation <span>Protection</span>
                                        </h2>
                                        <p> Aspetto is dedicated to protecting our military as they complete their
                                            multi-faceted aviation missions. In order to complete the aeronautical
                                            mission safely and successfully, the proper aircraft materials are a
                                            necessity.
                                        </p>
                                        <p>Aspetto offers premiumarmored helicopter panels, providing lightweight panels
                                            and ballistic windows to offer superior protection from small arms fire,
                                            whether in flight or on the ground.
                                        </p>
                                        <div className="hidediv collapse mb-3" id="demo1">
                                            <a className="btn btn-danger" target="blank"
                                               href="./images/Armored_Helicopters.pdf">View Capability</a>
                                        </div>
                                        <a className="f-w" data-toggle="collapse" data-target="#demo1">Read More...</a>
                                    </div>
                                </div>
                                <div className="row py-4" id="armored-vehicles">
                                    <div className="col-lg-3  push-lg-9 wow fadeInRight" data-wow-duration="1.3s">
                                        <img className="w-100 mb-3" src="./images/Armored.jpg"/>
                                    </div>
                                    <div className="col-lg-9  pull-lg-3 align-self-center wow fadeInLeft"
                                         data-wow-duration="1.3s">
                                        <h2 className="wow fadeInUp font-sm"
                                            data-wow-duration="1.3s">Armored <span>Vehicles</span></h2>
                                        <p>Government-based mission support is rarely ever stagnant, which means your
                                            troops, law enforcement officials and contractors need a safe operation
                                            vehicle to get from point <strong>A</strong> to
                                            point <br/><strong> B.</strong>
                                        </p>
                                        <p> Providing a safe transportation solution for all of your government needs is
                                            what Aspetto offers. Our armored vehicles are created with a safety-first
                                            mission in mind, offering protective vehicles of various makes and models to
                                            suit our client's needs.
                                        </p>
                                        <div className="hidediv collapse mb-3" id="demo2">
                                            <p>We'll evaluate your level of protection needs and deliver an armored
                                                vehicle which meets the internationally recognized ballistic
                                                standard.
                                            </p>
                                            <a className="btn btn-danger " target="blank"
                                               href="./images/Armored_Vehicles.pdf">View Capability</a>
                                        </div>
                                        <a className="f-w" data-toggle="collapse" data-target="#demo2">Read More...</a>
                                    </div>
                                </div>*/}
                                <div className="row py-4" id={'cyber-security'}>
                                    <div className="col-lg-3  wow fadeInLeft" data-wow-duration="1.3s">
                                        <img className="w-100 mb-3" src="./images/Cyber.jpg"/>
                                    </div>
                                    <div className="col-lg-9   align-self-center wow fadeInRight"
                                         data-wow-duration="1.3s">
                                        <h2 className="wow fadeInUp font-sm" data-wow-duration="1.3s">Cyber
                                            and <br/><span>IT Services</span>
                                        </h2>
                                        <p>Cybersecurity, web development, and systems engineering goals can be achieved
                                            when you obtain
                                            Aspetto's IT services.
                                        </p>
                                        <p> From providing in-depth analysis for detection of malicious activity to
                                            providing the necessary assistance for security projects, the Aspetto team
                                            will address and monitor all of your IT security needs.
                                        </p>
                                        <div className="hidediv collapse mb-3" id="demo3">
                                            <p>We work to exceed our customers’ expectations and goals by providing
                                                life-cycle management solutions pertaining to complex information
                                                technology requirements. The solutions are crafted to optimize
                                                performance, reduce total cost of ownership, and increase investment
                                                return. Aspetto will provide quality solutions to all of your business
                                                challenges.
                                            </p>
                                            <p><strong>Services: </strong></p>
                                            <ul>
                                                <li>VMware virtualization</li>
                                                <li> Cloud implementation/administration</li>
                                                <li> Symantec backup/recovery administration</li>
                                                <li>Infrastructure engineering/management</li>
                                                <li>Program/project management</li>
                                                <li>Information security and assurance</li>
                                                <li>COTS/GOTS evaluation</li>
                                                <li>Independent verification and validation (IV&amp;V)</li>
                                                <li>Client/server, web, and mobile application development</li>
                                                <li>Legacy system conversion</li>
                                                <li>Training</li>
                                                <li>Website Development</li>
                                            </ul>
                                            <p>Aspetto is ready to handle all ofyour extensive cybersecurity and IT
                                                requirements and our team is equipped to work with companies of all
                                                sizes and industries to achieve your individualized security goals.
                                            </p>
                                        </div>
                                        <a className="f-w" data-toggle="collapse" data-target="#demo3">Read More...</a>
                                    </div>
                                </div>
                               {/* <div className="row py-4" id={'logistics'}>
                                    <div className="col-lg-3  push-lg-9 wow fadeInRight" data-wow-duration="1.3s">
                                        <img className="w-100 mb-3" src="./images/Logistics.jpg"/>
                                    </div>
                                    <div className="col-lg-9  pull-lg-3 align-self-center wow fadeInLeft"
                                         data-wow-duration="1.3s">
                                        <h2 className="wow fadeInUp font-sm" data-wow-duration="1.3s">
                                            <span>Logistics</span>
                                        </h2>
                                        <p>Aspetto offers logistics services worldwide. From remote to hostile locations
                                            and beyond, your logistics needs are in good hands with Aspetto handling all
                                            of the finer details. The Aspetto team has the multi-disciplinary skills
                                            needed to help you achieve all of your occupational goals. With global
                                            capabilities and hands-on experience in select business sectors, you can
                                            rest easy knowing that Aspetto is in charge of your logistics needs and
                                            won't stop until the job is done.
                                        </p>
                                        <div className=" collapse mb-3" id="demo4">
                                            <p>Aspetto is an industry leader in global logistics services offering door
                                                to door, 3PL and Just in Time logistics. This isachieved by utilization
                                                of multiple, highly skilled freight forwarders and customs brokers and
                                                subsequentmanagement of elected forwarders to insure best possible rates
                                                for all transport modes both CONUS & OCONUS.An integral aspect of
                                                Aspetto’s logistics services is complete visibility within the supply
                                                chain. Aspetto works with serviceproviders, carriers and shipping lines
                                                to be able to provide real time shipment status at any point throughout
                                                thesupply chain so our clients can schedule and adjust their activities,
                                                as needed, around accurate material arrivaldates.
                                            </p>
                                            <p>Aspetto also provides the following services as part of the logistics
                                                suite of services: Negotiation of contract rates for all road, air and
                                                ocean shipments
                                            </p>
                                            <ul>
                                                <li>Spot rate quotes for project move cargo which are large volume /
                                                    quantity, out of gauge (weight and ordimension) with remote location
                                                    or impractical to ship through our consolidation warehouses
                                                </li>
                                                <li>Coordination with our clients for shipment modes for all ordered
                                                    materials
                                                </li>
                                                <li> QA/QC materials check, Striping, pick and pack, repack and
                                                    consolidation of materials at APS consolidationwarehouses for both
                                                    ocean and air shipments enabling material flexibility within the
                                                    supply chain. • Material management and inventory control for
                                                    warehouse materials
                                                </li>
                                                <li>Material tracking from order placement, transit and delivery to end
                                                    location
                                                </li>
                                            </ul>
                                            <p>Labor Supply Management</p>
                                            <ul>
                                                <li>Database with over ten thousand vetted resumes, OCN/EXPATS</li>
                                                <li>Rapid Deployment</li>
                                                <li>Internet based electronic timekeeping system</li>
                                            </ul>
                                            <p>Supply Chain Management</p>
                                            <ul>
                                                <li>Internet based procurement system (P2P)</li>
                                                <li>Over 500 Registered Suppliers</li>
                                                <li>Custom reporting to capture clients KPI’s</li>
                                            </ul>
                                            <p>Logistic Services</p>
                                            <ul>
                                                <li>Global Door to Door Services</li>
                                                <li>Uses Network of highly skilled Freight Forwarders / Customs
                                                    Brokers
                                                </li>
                                                <li>Experiences in FAR export Requirements</li>
                                            </ul>
                                        </div>
                                        <a className="f-w" data-toggle="collapse" data-target="#demo4">Read More...</a>
                                    </div>
                                </div>*/}
                            </div>
                            <div className="col-lg-3">
                                <PartnerEventComponent/>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="past-performance" className="modal fade carrere_model ecatloh" role="dialog">
                    <div className="modal-dialog">
                        <a href="#" data-dismiss="modal" className="pop_close_btn">x</a>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="pop_container container">
                                    <div className="row">
                                        <div className="col-sm-12 text-center">
                                            <div className="logo_pop">
                                                <img src="./images/footerlogo.png" alt="Logo"/>
                                            </div>
                                            <div className="contantpop-up">

                                                <div className="row py-4">
                                                    <div className="col-lg-5   ">

                                                        {this.state.past_performance.blog_single_images &&
                                                        <img className="w-100"
                                                             src={this.state.past_performance.blog_single_images.full}/>}

                                                    </div>
                                                    <div className="col-lg-7  align-self-center text-left">
                                                        <h2 className="font-sm">{this.state.past_performance.title}</h2>
                                                        <p dangerouslySetInnerHTML={{__html: this.state.past_performance.content}}/>


                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CapabilitiesPage))
