import React, {Component} from 'react';
import './register.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import CommonHelper from "../../helper/CommonHelper";
import SimpleReactValidator from 'simple-react-validator'

class RegisterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password: ''
        };
        this.handleChanges = this.handleChanges.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }

    handleChanges(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.validator.allValid()) {

            CommonHelper.post('register', this.state, (status, response) => {
                this.props.history.push('/login');
            });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    }


    render() {

        return (
            <div className="component-register-page">
                <Breadcrumb activePageName={'Register'}/>
                <div className="ps-page">
                    <div className="ps-account">
                        <div className="container">
                            <form className="ps-form--account" onSubmit={this.handleSubmit}>
                                <div className="ps-form__header">
                                    <h3>Register</h3>
                                </div>
                                <div className="ps-form__content">
                                    <div className="form-group">
                                        <label>First Name <sup>*</sup></label>
                                        <input className="form-control" type="text" name={'first_name'}
                                               onChange={this.handleChanges}/>
                                        {this.validator.message('first_name', this.state.first_name, 'required|alpha')}
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name <sup>*</sup></label>
                                        <input className="form-control" type="text" name={'last_name'}
                                               onChange={this.handleChanges}/>
                                        {this.validator.message('last_name', this.state.last_name, 'required|alpha')}
                                    </div>
                                    <div className="form-group">
                                        <label>Email <sup>*</sup></label>
                                        <input className="form-control" type="text" name={'email'}
                                               onChange={this.handleChanges}/>
                                        {this.validator.message('email', this.state.email, 'required|email')}
                                    </div>
                                    <div className="form-group">
                                        <label>Password <sup>*</sup></label>
                                        <input className="form-control" type="password" name={'password'}
                                               onChange={this.handleChanges}/>
                                        {this.validator.message('password', this.state.password, 'required|min:8')}
                                    </div>
                                    <div className="form-group submit">
                                        <p>Your personal data will be used to support your experience throughout this
                                            website, to manage access to your account, and for other purposes described
                                            in our privacy policy.</p>
                                        <button className="ps-btn ps-btn--outline ps-btn--black"
                                                type={'submit'}>Register
                                        </button>
                                        <p><strong> or<Link to={'/login'}> Login</Link></strong></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPage))