import React, {Component} from 'react';
import CommonHelper from "../../../helper/CommonHelper";
import SimpleReactValidator from "simple-react-validator";
import {Link, withRouter} from "react-router-dom";

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            CommonHelper.post('subscribeDefence', {email: this.state.email}, (status, response) => {
                this.setState({email: ''});
            });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }

    }

    render() {
        return (
            <div>
                <footer>
                    <div className="container">
                        
                            <div className='footarmenu'>
                            <div className="item-footer">
                            <h3>Aspetto</h3>
                                <p className='mb-0'>
                                   1691 Emancipation Hwy.<br/>


                                   Fredericksburg,  VA 22401 USA 
                                
                                    </p>
                                    <span><i className="far fa-envelope"></i> info@aspettoinc.com</span>
                                   

                            </div>
                            <div className="item-footer">
                                <h3>Links</h3>
                                <div className="menufootaer">
                                    <ul>
                                        <li><Link to={'/about-us'}>About Us</Link></li>
                                        <li><Link to={'/tactical'}>Tactical</Link></li>
                                        <li><Link to={'/technical'}>Tech</Link></li>
                                        <li><Link to={'/labs'}>Labs</Link></li>
                                        <li><Link to={'/digital-media'}>Digital Media</Link></li>
                                        <li><Link to={'/careers'}>Careers</Link></li>
                                    </ul>
                                </div>
                            </div>
                                <div className="item-footer">
                                      <h3>Social Media</h3>
                                    <ul className='social-media-links'>
                                        <li><a href="https://www.facebook.com/aspettoinc" target="blank"><i
                                            className="fab fa-facebook-f"/></a></li>
                                        <li><a href="https://www.instagram.com/aspetto" target="blank"><i
                                            className="fab fa-instagram" aria-hidden="true"/></a></li>
                                        <li><a href="https://www.twitter.com/aspettoinc" target="blank"><i
                                            className="fab fa-twitter" aria-hidden="true"/></a></li>
                                        <li><a href="https://www.linkedin.com/company/aspettoinc" target="blank"><i
                                            className="fab fa-linkedin" aria-hidden="true"/></a></li>
                                        {/*<li><a href="javascript:void(0)" target="blank"><i className="fa fa-youtube" aria-hidden="true"></i></a></li>*/}
                                    </ul>
                                </div>
                            </div>
                        
                    </div>
                </footer>
                <div className="bottenfooter ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6"><p className="all">© Aspetto Inc.- All Rights Reserved</p></div>
                            <div className="col-md-6">

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
