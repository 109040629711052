import CommonHelper from "../../helper/CommonHelper";
import Config from "../../helper/Config";

export const loginAction = (data, cb) => {
    console.log("Login Action", data);
    return (dispatch, props) => {
        dispatch({
            type: 'LOGIN_REQUEST',
            payload: data
        });

        CommonHelper.post('login', data, (status, response) => {
            var res = JSON.parse(Config.decrypt(response.data.scalar, true));
            localStorage.setItem('user', Config.encrypt(JSON.stringify(res.user)));
            localStorage.setItem('token', res.token);
            if (res.user) {
                Config.conf.user = res.user;
                Config.conf.token = res.token;

                dispatch({
                    type: 'USER',
                    payload: res.user
                });
            }
            cb(status, res)
        });

    }
};
