import React, {Component} from 'react';
import './labs.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import {withRouter} from "react-router-dom";
class LabsPage extends Component {
constructor(props) {
super(props);
}


componentDidMount(){

  new window.Swiper('.swiper-technical', {
     slidesPerView: 1,
     loop:true,
     navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
 
     
  });

}


render() {
return (
<div className={'about-us-page  abouthding'}>
<div className="video-slider">
          <div className="container">
            <div className="swiper-technical">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <video width="100%" height="100%" id="myVideo"preload="metadata" autoPlay={true} loop={true} playsInline={true}>
                    <source src="images/Aspetto Lab First One.mp4" type="video/mp4" />
                  </video>
                  <div className="hero-line">
                    <h3>Ever changing the status quo</h3>
                  </div>
                </div>
                <div className="swiper-slide">
                <div className="swiper-slide">
                  <video width="100%" height="100%" id="myVideo" preload="metadata" autoPlay={true} loop={true} playsInline={true}>
                    <source src="images/Aspetto Lab Second.mp4" type="video/mp4" />
                  </video>
				  

                </div>
                </div>
              </div>
              {/* next / prev arrows */}
              <div className="swiper-button-next" />
              <div className="swiper-button-prev" />
            </div>
          </div>
        </div>
        <div className="wrapper-content">
          <div className="container">
            <div className="tectical-about mt-0 mb-0">
              <div className="pera-content mt-0 mb-0">
                <h2>More than an investment:</h2>
                <p className="text-center">When we invest in your startup, we don’t just provide capital, we also assist you in infrastructure support, bringing your idea to life, and then pitch it to the largest customer, the US Government. </p>
              </div>
            </div>
            <div className="bg-content-tectical text-center">
                  <div className="btn-group">
                      <Link to={'/getquote'} className="btn btn-tectical">
                      Request a Quote
                      </Link>
                  </div>
            </div>
            <div className="row-with-border">
              <div className="heading-bold-content">
                <h3>We are ISO 9001:2015 certified</h3>
              </div>
              <div className="heading-bold-peragraph">
                <p>We hire industry leading and innovative subject matter experts, maintaining a dedicated team to provide timely, effective, and proven innovative solutions to our customers world-wide.</p>
              </div>
            </div>
          </div>
          <div className="tectical-partneers">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <h3>Current Portfolio:</h3>
                  {/*<img src="images/army-man.png">*/}
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="flex-partners about-vehicles">
                  <div className="item"><img src="images/portfolios/Logo_ArcX.svg" /></div>
                    <div className="item"><img src="images/portfolios/01.png" /></div>
                    <div className="item"><img src="images/portfolios/02.png" /></div>
                    <div className="item"><img src="images/logopopsmoke.png" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="labs-services">
            <div className="container">
              <div className="tetical-headlines">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <h3>In-House Engineering</h3>
                    {/*<img src="images/army-man.png">*/}
                  </div>
                  <div className="col-md-6">
                    <p>Our engineering team follows a product development strategy created to ensure project completion that’s comprehensive and timely.</p>
                  </div>
                </div>
              </div>
              <div className="tetical-headlines">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <p>We use SolidWorks CAD software for modeling and then validate our designs by carrying out the Finite Element Analysis (static, dynamic, and thermal) using SolidWorks Simulation Capability.</p>
                  </div>
                  <div className="col-md-6">
                    <h3 className="text-end">Modeling and Validation </h3>
                    {/*<img src="images/army-man.png">*/}
                  </div>
                </div>
              </div>
              <div className="tetical-headlines">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <h3>Rapid Prototyping </h3>
                    {/*<img src="images/army-man.png">*/}
                  </div>
                  <div className="col-md-6">
                    <p>We will complete your prototypes in-house at our rapid prototyping facility. At the facility, the prototype will go through three stages involving a Form3 3D printer, form wash, and form cure.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
</div>
);
}
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LabsPage))
