import React, {Component} from 'react';
import './technical.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import {withRouter} from "react-router-dom";
class TechnicalPage extends Component {
constructor(props) {
super(props);
}


render() {
return (
<div className={'about-us-page  abouthding'}>
<div className="video-slider">
          <div className="container">
            <div className="swiper-technical">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <video width="100%" height="100%" id="myVideo" autoPlay={true} loop={true} playsInline={true}>
                    <source src="images/Aspetto Tech Video.mp4" type="video/mp4" />
                  </video>
                <div className="hero-line">
                    <h3>Tech in your hands – <span>from the network to the battlefront</span></h3>
                  </div>
                </div>
              </div>
              {/*<div className="swiper-button-next" />
              <div className="swiper-button-prev" />*/}

            </div>
          </div>
        </div>
        <div className="wrapper-content">
          <div className="container">
            <div className="tectical-about mt-0 mb-0">
              <div className="pera-content mt-0 mb-0">
                <p className="text-center">Our tech division has earned a reputation for being precise, dependable, and technically superior: Tech to Tactical – We’ve Got You, Your Infrastructure, and Your Data Covered.</p>
              </div>
            </div>
            <div className="bg-content-tectical text-center">
                  <div className="btn-group">
                     {/*<Link to={'/getquote'} className="btn btn-tectical">
                      Request a Quote
                      </Link>*/}
                      <a href='pdf/aspetto-tech-capabilities.pdf' className="btn btn-tectical" target={'blank'}>
                        View Our Technical Capabilities
                      </a>
                  </div>
            </div>
            <div className="row-with-border">
              <div className="heading-bold-content">
                <h3>Systems Engineering and IT Support Since 2008</h3>
              </div>
              <div className="heading-bold-peragraph">
                <p>We have a dynamic, innovative, and progressive outlook which empowers our team to develop technologically advanced solutions for our clients. </p>
              </div>
            </div>

            {/*<div className="bg-content-tectical text-center pt-4">
              <div className="btn-group">
                <a href="#" className="btn btn-tectical">
                  Careers 
                  <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M478,256,302,432l-21.21-21.2L420.6,271H34V241H420.6L280.75,101.16,302,80Z" />
                  </svg>
                </a>
              </div>
            </div>*/}
          </div>
        </div>
        <div className="tectical-services">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="tech-item">
                  <div className="content-technical">
                    <img src="images/partners-logo/icon-01.png" alt="partners" />
                    <h4>Enterprise IT and Systems Engineering</h4>
                    <p>System modernization should be happening all the time. We have delivered responsive enterprise IT and systems engineering services to tackle the largest and most complex of enterprise systems.</p>
                  </div>
 
                </div>
              </div>
              <div className="col-md-4">
                <div className="tech-item">
                  <div className="content-technical">
                    <img src="images/partners-logo/icon-02.png" alt="partners" />
                    <h4>Web and Mobile Development</h4>
                    <p>Tech in your hands and where you are. Whether you are at your desk or on the go, our team of Architects and DevSecOps experts design and deliver secure frameworks for multiple platforms leveraging hardware, CAC, and biometric authentication deployable to classified and tactical environments.</p>
                  </div>
                  
                </div>
              </div>
              <div className="col-md-4">
                <div className="tech-item">
                  <div className="content-technical">
                    <img src="images/partners-logo/icon-03.png" alt="partners" />
                    <h4>Cloud and Infrastructure</h4>
                    <p>	Our cloud services delivery team always has their heads in the cloud and their feet firmly planted on the ground.  Our subject matter experts can transition any organization from a locally managed enclave to the cloud. </p>
                  </div>
                 
                </div>
              </div>
              <div className="col-md-4">
                <div className="tech-item">
                  <div className="content-technical">
                    <img src="images/partners-logo/icon-04.png" alt="partners"/>
                    <h4>Cybersecurity</h4>
                    <p>	We recognize that cybersecurity is essential; we craft fast, secure, and robust platforms that serve millions of users deployed across a variety of networks.</p>
                  </div>
                  
                </div>
              </div>
              <div className="col-md-4">
                <div className="tech-item">
                  <div className="content-technical">
                    <img src="images/partners-logo/icon-05.png" alt="partners"/>
                    <h4>Test, Training, and Evaluation</h4>
                    <p>	Our assessments comply with all operational, performance, and user requirements and are backed by real-time auditing of your datacenter and/or multi-cloud infrastructure and the systems deployed within them. </p>
                  </div>
                 
                </div>
              </div>
              <div className="col-md-4">
                <div className="tech-item">
                  <div className="content-technical">
                    <img src="images/partners-logo/icon-06.png" alt="partners"/>
                    <h4>Advanced Data Management</h4>
                    <p>	JSON, FHIR, SOAP, REST, XML, Flat Files? Your data format doesn’t bother us, and it shouldn’t bother you. </p>
                  </div>
                 
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="tech-item">
                  <div className="content-technical">
                    <img src="images/partners-logo/icon-07.png" alt="partners"/>
                    <h4>Artificial Intelligence, Machine Learning, and Blockchain </h4>
                    <p>Provable and immutable data access auditing via Blockchain technologies like Quantum Ledger is at the heart of our approach to maintaining and enhancing environment data security.</p>
                  </div>
                 
                </div>
              </div>
              <div className="col-md-8">
                <div className="tech-item">
                  <div className="content-technical">
                    <img src="images/partners-logo/icon-08.png" alt="partners"/>
                    <h4>	Aspetto IT Products :: <span className=''>LAYR®</span></h4>
                    
                    
                    <p>	LAYR is a DevOps as a Service in your own environment using Infrastructure as Code (IaC) and Software Development Kits (SDK) without making specific technology decisions for a program.  LAYR offers CAC as a Services (CaaS), which is a simplified, customer managed authentication provider with CAC enablement in your own environment and adds a modern authentication interface that unifies your existing identity management and brings CAC/PIV capabilities to web and mobile devices.  LAYR is multi-cloud supported in Azure, AWS GovCloud, and Google Cloud.</p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
</div>
);
}
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicalPage))
