import React, {Component} from 'react';
import CommonHelper from "../helper/CommonHelper";
import SimpleReactValidator from "simple-react-validator";

export default class QuotePopup extends Component {
    constructor(props) {
        super(props);
        this.quoteSubmit = this.quoteSubmit.bind(this);
        this.onAddressChanged = this.onAddressChanged.bind(this);
        this.handleChangesReview = this.handleChangesReview.bind(this);
        this.state = {
            country: [],
            quote: {
                product_id: '',
                first_name: '',
                last_name: '',
                phone: '',
                country: '',
                email: '',
                are_you:'',
                quote_msg: ''
            },
        };

        this.validator = new SimpleReactValidator();
    }

    handleChangesReview(event) {
        this.setState({quote: {...this.state.quote, [event.target.name]: event.target.value}})
    }

    quoteSubmit(event) {
        event.preventDefault();
        if (this.validator.allValid()) {
            CommonHelper.post('needQuote', this.state.quote, (status, response) => {
                if (response.status) {
                    this.setState({
                        quote: {
                            first_name: '',
                            last_name: '',
                            phone: '',
                            country: '',
                            email: '',
                            are_you:'',

                        }
                    })
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onAddressChanged(e) {
        this.setState({
            quote: {
                ...this.state.quote,
                are_you: e.currentTarget.value
            }
        });
    }

    render() {
        const country = this.props.country.map((country) => {
            return (
                <option key={country.id} value={country.name}>{country.name}</option>
            );
        });
        return ( 
       
      
            <div className="contact-form-box">
				<div className="row">
                    <div className="col-md-12 text-center">
                    <h3>Need A Quote?</h3>
                    <p> Products are available for DOD, Federal, State, and Local Government Agency ordering. Dependent on the product, there may be restrictions where a background check is required by Aspetto. Contact us for quotes, info, and ordering.</p>
                    </div>
                </div>		 

   

                                             
                                             <form id="contact-form" onSubmit={this.quoteSubmit}>
                                             <div className="messages"/>
                                             
                                                     <div className="row">
                                                         <div className="col-md-6">
                                                             <div className="form-group">
                                                                 <label htmlFor="form_name">First
                                                                     Name </label>
                                                                 <input id="form_name"
                                                                        type="text"
                                                                        name="first_name"
                                                                        value={this.state.quote.first_name || ''}
                                                                        className="form-control"
                                                                        placeholder="Please enter your name *"
                                                                        onChange={this.handleChangesReview}
                                                                 />
                                                                 {this.validator.message('first name', this.state.quote.first_name, 'required')}
                                                             </div>
                                                         </div>
                                                         <div className="col-md-6">
                                                             <div className="form-group">
                                                                 <label htmlFor="form_name">Last
                                                                     Name </label>
                                                                 <input id="form_name"
                                                                        type="text"
                                                                        name="last_name"
                                                                        value={this.state.quote.last_name || ''}
                                                                        className="form-control"
                                                                        placeholder="Please enter your name *"
                                                                        onChange={this.handleChangesReview}
                                                                 />
                                                                 {this.validator.message('last_name', this.state.quote.last_name, 'required')}
                                                             </div>
                                                         </div>
                                                         <div className="col-md-6">
                                                             <div className="form-group">
                                                                 <label htmlFor="form_email">
                                                                     Email </label>
                                                                 <input id="form_email"
                                                                        type="email"
                                                                        name="email"
                                                                        value={this.state.quote.email || ''}
                                                                        className="form-control"
                                                                        placeholder="Please enter your email *"
                                                                        onChange={this.handleChangesReview}
                                                                 />
                                                                 {this.validator.message('email', this.state.quote.email, 'required|email')}
                                                             </div>
                                                         </div>
                                                         <div className="col-md-6">
                                                             <div className="form-group">
                                                                 <label>Phone (xxx) xxx-xxxx </label>
                                                                 <input id="form_email"
                                                                        type="text"
                                                                        name="phone"
                                                                        value={this.state.quote.phone || ''}
                                                                        className="form-control"
                                                                        placeholder="Please enter your phone *"
                                                                        onChange={this.handleChangesReview}
                                                                 />
                                                                 {this.validator.message('phone', this.state.quote.phone, 'required')}
                                                             </div>
                                                         </div>
                                                         <div className="col-md-6">
                                                             <div className="form-group">
                                                                 <label>Country</label>
                                                                 <select
                                                                     className="form-control"
                                                                     name="country"
                                                                     onChange={this.handleChangesReview}
                                                                     value={this.state.quote.country || ''}>
                                                                     <option value={''}>Select Country
                                                                     </option>
                                                                     {country}
                                                                 </select>
                                                                 {this.validator.message('country', this.state.quote.country, 'required')}
                                                             </div>
                                                         </div>


                                                         <div className="col-md-6">
                                                             <div className="form-group">


                                                                 <label>Are You </label>
                                                                 <div className="become_radio">

                                                                     <input
                                                                         type="radio"
                                                                         id="Federal"
                                                                         name="radio-group"
                                                                         value={'Federal'}
                                                                         checked={this.state.quote.are_you === 'Federal'}
                                                                         onChange={this.onAddressChanged}
                                                                     />
                                                                     <label htmlFor="Federal">Federal</label>


                                                                     <input
                                                                         type="radio"
                                                                         id="State"
                                                                         name="radio-group"
                                                                         value={'State'}
                                                                         checked={this.state.quote.are_you === 'State'}
                                                                         onChange={this.onAddressChanged}
                                                                     />
                                                                     <label htmlFor="State">State</label>

                                                                     <input
                                                                         type="radio"
                                                                         id="Local"
                                                                         name="radio-group"
                                                                         value={'Local'}
                                                                         checked={this.state.quote.are_you === 'Local'}
                                                                         onChange={this.onAddressChanged}
                                                                     />
                                                                     <label htmlFor="Local">Local</label>

                                                                     <input
                                                                         type="radio"
                                                                         id="Foreign"
                                                                         name="radio-group"
                                                                         value={'Foreign'}
                                                                         checked={this.state.quote.are_you === 'Foreign'}
                                                                         onChange={this.onAddressChanged}
                                                                     />
                                                                     <label htmlFor="Foreign">Foreign</label>

                                                                     <input
                                                                         type="radio"
                                                                         id="Civilian"
                                                                         name="radio-group"
                                                                         value={'Civilian'}
                                                                         checked={this.state.quote.are_you === 'Civilian'}
                                                                         onChange={this.onAddressChanged}
                                                                     />
                                                                     <label htmlFor="Civilian">Civilian</label>

                                                                 </div>
                                                                 {this.validator.message('are_you', this.state.quote.are_you, 'required')}
                                                             </div>
                                                         </div>
                                                         <div className="col-md-12">
                                                             <div className="form-group">


                                                                 <label>Requesting a Quote For </label>
                                                                 <textarea
                                                                     rows="4"
                                                                     className="form-control"
                                                                     cols="50"
                                                                     value={this.state.quote.quote_msg || ''}
                                                                     name={'quote_msg'}
                                                                     onChange={this.handleChangesReview}
                                                                 />
                                                                 {this.validator.message('quote_message', this.state.quote.quote_msg, 'required')}
                                                             </div>
                                                         </div>
                                                         {/*<div className="col-md-6">
                                                         <div className="form-group">
                                                             <label>Organization Type</label>
                                                             <select
                                                                 className="form-control"
                                                                 name="country"
                                                                 onChange={this.handleChangesReview}
                                                                 value={this.state.quote.country || ''}>
                                                                 <option value={''}> Please Select</option>
                                                                 {country}
                                                             </select>
                                                             {this.validator.message('country', this.state.quote.country, 'required')}
                                                         </div>
                                                     </div>*/}
                                                     </div>
                                                   

                                                         <div className="col-md-12 text-center mt-3">
                                                             <input type="submit"
                                                                    className="btn btn-success btn-send"
                                                                    defaultValue="Submit Reviews"/>
                                                         </div>
                                                     
                                            
                                             </form>
                                        


                                     </div>)
    }
}