import React from "react";
import CommonHelper from "../helper/CommonHelper";
import SimpleReactValidator from "simple-react-validator";

export default class PartnerEventComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            partner: {
                title: '',
                company_name: '',
                company_website: '',
                full_name: '',
                email: '',
                phone: '',
                type: 'supplier'
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChanges = this.handleChanges.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.getEvents();

    }

    handleChanges(event) {
        this.setState({partner: {...this.state.partner, [event.target.name]: event.target.value}})
    }

    getEvents() {
        CommonHelper.get('getEvents', (status, res) => {
            this.setState({events: res.data.data});
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.validator.allValid()) {
            CommonHelper.post('add-partners', this.state.partner, (status, response) => {
                if (response.status) {
                    this.setState({
                        partner: {
                            title: '',
                            company_name: '',
                            company_website: '',
                            full_name: '',
                            email: '',
                            phone: '',
                            type: 'supplier'
                        }
                    })
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        let events = this.state.events.map((event, i) => {
            let cls = (i == 0) ? 'item active' : 'item';
            return (
                <div key={event.id} className={cls}>
                    <img src={event.thumb} className="mb-3" alt="The Last of us"/>
                    <p dangerouslySetInnerHTML={{__html: event.content}}/>
                </div>
            );
        });
        return (<div className="aside wow fadeInUp" data-wow-duration="1.3s">
            <div className="quatto">
                <span>Partner with Aspetto</span>
            </div>
            <img className="w-100 mb-3" src="./images/partner.jpg"/>
            <aside className="secondary">
                <p>Aspetto is always interested in building strategic relationships with other
                    companies who have similar values, ethics, and missions. Does this sound
                    like something your company might be interested in?
                </p>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input type="Title"
                               className="form-control"
                               placeholder="Title: *"
                               name="title"
                               value={this.state.partner.title || ''}
                               onChange={this.handleChanges}
                               id="title"/>
                        {this.validator.message('title', this.state.partner.title, 'required')}
                    </div>
                    <div className="form-group">
                        <input type="Full Name"
                               className="form-control"
                               placeholder="Full Name: *"
                               name="full_name"
                               value={this.state.partner.full_name || ''}
                               onChange={this.handleChanges}
                               id="full_name"/>
                        {this.validator.message('full_name', this.state.partner.full_name, 'required')}
                    </div>
                    <div className="form-group">
                        <input type="email" className="form-control"
                               placeholder="Email: *"
                               name="email"
                               value={this.state.partner.email || ''}
                               onChange={this.handleChanges}
                               id="email"/>
                        {this.validator.message('email', this.state.partner.email, 'required|email')}
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control"
                               placeholder="Company Name: *"
                               name="company_name"
                               value={this.state.partner.company_name || ''}
                               onChange={this.handleChanges}
                               id="company_name"/>
                        {this.validator.message('company_name', this.state.partner.company_name, 'required')}
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control"
                               placeholder="Company Website: *"
                               name="company_website"
                               value={this.state.partner.company_website || ''}
                               onChange={this.handleChanges}
                               id="company_website"/>
                        {this.validator.message('company_website', this.state.partner.company_website, 'required')}
                    </div>

                    <div className="form-group">
                        <input type="tel" placeholder="Phone: *"
                               name="phone"
                               value={this.state.partner.phone || ''}
                               onChange={this.handleChanges}
                               className="form-control"/>
                        {this.validator.message('phone', this.state.partner.phone, 'required')}
                    </div>
                    <div className="new">
                        <p className="become_radio">
                            <span className="become_label">Become a</span>
                            <input type="radio" id="test1" name="type"
                                   value={'supplier'}
                                   onChange={this.handleChanges}
                                   checked={this.state.partner.type === 'supplier'}
                            />
                            <label htmlFor="test1">Supplier</label>
                            <input type="radio" id="test2" name="type"
                                   value={'distributor'}
                                   checked={this.state.partner.type === 'distributor'}
                                   onChange={this.handleChanges}/>
                            <label htmlFor="test2">Distributor</label>
                        </p>
                        {this.validator.message('type', this.state.partner.type, 'required')}
                    </div>
                    <div className="form-group text-center">
                        <button type="submit" className="btn ">Submit</button>
                    </div>
                </form>
                <div className="quatto">
                    <span>Upcoming Events</span>
                </div>
                {this.state.events.length > 0 &&
                <div id="owl-demo34" className="owl-carousel owl-theme topslider">
                    {events}
                </div>
                }
            </aside>
        </div>)
    }
}