import React, {Component} from 'react';
import './digitalmedia.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
class DigitalmediaPage extends Component {
constructor(props) {
super(props);
}


render() {
return (
<div className={'about-us-page  abouthding'}>

       
        <div className="wrapper-content mb-5">
          <div className="container">

              {/* <iframe src="https://www.popsmokemedia.com/" title="popsmokemedia" className='video-iframe' frameBorder="0"></iframe> */}

            <div className="tectical-about mt-0 mb-0">
              <div className="pera-content mb-0">
            <img className='logo-smoke mb-4' src="images/logopopsmoke.png" />
                <p className="text-center pb-sm-5 ">Our digital media efforts have been an integral part of the military and the first responders community. With an audience of nearly a million followers, we connect with our service members, veterans, and first responders on a different level.</p>
              </div>
            </div>

            <div className="row-with-border">
              <div className="digi-bold-content">
                <h3>- Bringing positive change to our troops since 2015</h3>
              </div>
            </div>
            <div className="row-with-border">
              <div className="digi-bold-content">
                <h3>- Nearly a million followers – active and engaging members contribute to our community</h3>
              </div>
            </div>
            <div className="row-with-border">
              <div className="digi-bold-content">
                <h3>- Our <a href=" https://podcasts.apple.com/us/podcast/the-smoke-pit/id1441222048" target={'blank'}>podcast</a> has over 500,000 downloads and millions of streams.   </h3>
              </div>
			  
			 
            </div>
            <div className="row-with-border">
              <div className="digi-bold-content">
                <h3> - 100,000+ unique readers on our <a href="https://www.popsmokemedia.com/" target={'blank'}>news website </a></h3>
              </div>
            </div>
            <div className="row-with-border">
              <div className="digi-bold-content">
                <h3>- Bringing new and unique approaches to your recruiting needs. </h3>
              </div>
            </div>
            <div className="row-with-border">
              <div className="digi-bold-content">
                <h3>- We are helping federal agencies, the military, and commercial markets get their message out to a larger audience. </h3>
              </div>
            </div>
          </div>
        </div>
       
</div>
);
}
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalmediaPage))
