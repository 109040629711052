import React, {Component} from 'react';
import './protective-solutions.scss'
import {Breadcrumb} from "../../components/breadcrumb.component";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class ProtectiveSolutionsPage extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <div>
                <div id="owl-demo" className="owl-carousel owl-theme topslider inner-banner">
                    <div className="item">
                        <img src="./images/Aviation.jpg" alt="The Last of us"/>
                 
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 mb-3">
                                <div className=" row">
                                    <div className="  px-3 px-lg-5  align-self-center">
                                        <h2>Protective <span>Solutions</span>
                                        </h2>
                                        <div className="inner_image">
                                            <img className="w-100" src="./images/img-1.jpg" alt=" " title/>
                                        </div>
										
										
										
										
										
										 <p>Aspetto manufactures and distributes cutting-edge, high performance
                                            protective ballistic solutions for the U.S. Military and both domestic and
                                            international law enforcement communities. Through our focus on research and
                                            development, coupled with unique systems integration capabilities and
                                            innovative technology, Aspetto Inc. has developed patented solutions for
                                            some of the highest performing ballistic armor carrier systems
                                            available.</p>
											
											<p> Aspetto Inc. has successfully delivered thousands of armored products to the Department of Defense, Department of State, and various other federal and state law enforcement agencies. Our goal is to continue to develop new applications and innovations for ballistic systems, by incorporating every latest advancement available in ballistic materials and technology. In addition, Aspetto Inc. continues to demonstrate an unwavering commitment to the highest customer service standards throughout our industry.</p>
                                        <button class="btn btn-danger ">View Full Capability Statement</button>
                                    </div>
                                </div>
                            </div>
                           <div class="col-lg-3" >
					<div class="aside <!--bggray---> wow fadeInUp" data-wow-duration="1.3s">
					
					
					           <div class="quatto">
                    <span>Partner with Aspetto</span>
                </div>
				 <img className="w-100 mb-3" src="./images/partner.jpg" alt title/> 
				
                <aside class="secondary">

                    <p>Aspetto is always interested in building strategic relationships with other companies who have similar values, ethics, and missions. Does this sound like something your company might be interested in?</p>


                    <form action="/action_page.php">

                        <div class="form-group">

                            <input type="email" class="form-control" placeholder="Full Name: *" id="email" />
                        </div>
                        <div class="form-group">

                            <input type="email" class="form-control" placeholder="Title: *"
                                   id="email"/>
                        </div>
						 <div class="form-group">

                            <input type="email" class="form-control" placeholder="Company Name: *"
                                   id="email"/>
                        </div>
						 <div class="form-group">

                            <input type="email" class="form-control" placeholder="Company Website: *"
                                   id="email"/>
                        </div>
                        <div class="form-group">

                            <input type="email" class="form-control" placeholder="Email Address: *" id="email"/>
                        </div>
                        <div class="form-group">

                            <input type="tel" placeholder="Phone: *" class="form-control"/>
                        </div>
						
						
						<div class="new">
  <form> 
	 
  <p class="become_radio">
  <span class="become_label">Become a</span>
   <input type="radio" id="test1" name="radio-group" checked/>
    <label for="test1">Supplier</label>
    <input type="radio" id="test2" name="radio-group"/>
    <label for="test2">Distributor</label>
  </p>
   
  </form>
</div>
           <div class="form-group text-center">
                        <button type="submit" class="btn ">Submit</button>
						</div>
                    </form>
	           <div class="quatto">
                    <span>Upcoming Events</span>
					
					
                </div>
				<div id="owl-demo" className="owl-carousel owl-theme topslider">
                    <div className="item">
                        <img src="./images/event.jpg" class="mb-3" alt="The Last of us"/>
                      
              
                     <p>Find out more about Aspetto's offerings by attending one of the company's upcoming events. Learn about the services Aspetto provides and find out how you can partner with our dynamic and professional team.</p>
                    </div>
					
					                <div className="item">
                        <img src="./images/event.jpg" class="mb-3" alt="The Last of us"/>
                       <p>Find out more about Aspetto's offerings by attending one of the company's upcoming events. Learn about the services Aspetto provides and find out how you can partner with our dynamic and professional team.</p>

              
                    
                    </div>
                </div>
           
                </aside>
				
						
					</div>
					</div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectiveSolutionsPage))